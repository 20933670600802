import { apiRoutes, getAuthHeader } from "./index";

export const uploadTranslations = (file) => {

    const data = new FormData()
    data.append('file', file)


    return fetch(apiRoutes.uploadTranslations, {
        method: 'POST',
        // mode: 'no-cors',
        body: data,
        headers: getAuthHeader()
    })
        .then(response => {
            return response.json()
        })


}
