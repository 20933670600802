import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";

import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import Checkbox from "@material-ui/core/Checkbox";

import {
    Container,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Switch,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Divider,
    OutlinedInput,
    Select,
    Grid,
    Card,
    Chip,
    CardContent,
    FormControl,
    InputLabel,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Toolbar,
    IconButton,
    DialogContentText,
    DialogContent,
    DialogTitle,
    DialogActions,
    LinearProgress,
    InputBase
} from "@material-ui/core";

import { fade, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

export function OrgName({ value, setValue }) {
    return <TextField
        id="outlined-dense"
        label="Organization Name"
        margin="dense"
        variant="outlined"
        value={value}
        onChange={event => {
            setValue(event.target.value);
        }}
    />;
}

export function OrgContinue({ value, setValue, label }) {
    return <FormControlLabel
        control={<Checkbox
            checked={value}
            color="primary"
            onChange={() => {
                setValue(!value);
            }}
        />} label={label}
    />;
}

function HouseForm({ onSubmit, uploading, uploadError, createdOrg, handleClose, hasContinueFlag, hasUsageDataFlag }) {

    const classes = useStyles();
    const [name, setName] = React.useState("");
    const [shouldContinue, setShouldContinue] = React.useState(true);
    const [collectUsageData, setCollectUsageData] = React.useState(false);
    const [error, setError] = React.useState("");



    const validate = () => {
        let isValid = true;
        if (!name) {
            isValid = false;
            setError("name is required")
        }

        return isValid;
    }


    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <Fragment>
                        <form
                            style={{display: "flex", flexWrap: "wrap"}}
                            noValidate
                            autoComplete="off"
                        >
                            {OrgName({value: name, setValue: setName})}
                        </form>
                        {hasContinueFlag && <form
                            style={{display: "flex", flexWrap: "wrap"}}
                            noValidate
                            autoComplete="off"
                        >
                            {OrgContinue({value: shouldContinue, setValue: setShouldContinue, label: "continue app after checkup"})}
                        </form>}
                        <form
                            style={{display: "flex", flexWrap: "wrap"}}
                            noValidate
                            autoComplete="off"
                        >
                            {OrgContinue({value: collectUsageData, setValue: setCollectUsageData, label: "collect usage data"})}
                        </form>
                    </Fragment>
                )}

                {error}
                {uploadError && (<div style={{color: "red"}}>error creating org</div>)}
                {createdOrg ? (
                    <div>
                        <div>Success</div>
                    </div>
                ) : null}
            </Paper>

            {createdOrg ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    Done
                </Button>
            ) : (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        if (!validate()) {
                            return;
                        }
                        setError("")
                        hasContinueFlag ? onSubmit({ name, shouldContinue }) : onSubmit({ name, collectUsageData })
                    }}
                >
                    Submit
                </Button>
            )}


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateOrganizationDialog({ open, handleClose, formProps, onSubmit, createdOrg, uploading, uploadError, hasContinueFlag, hasUsageDataFlag }) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <HouseForm onSubmit={onSubmit} uploading={uploading} createdOrg={createdOrg} uploadError={uploadError} handleClose={handleClose}
                hasContinueFlag={hasContinueFlag} hasUsageDataFlag={hasUsageDataFlag} />
        </Dialog>
    );
}

