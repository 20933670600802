import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import { getDefaultSong, setYoutubePlaylistsSearchQuery, addSongToState, deleteSongFromState } from "../../modules/youtubeSongs";
import { fetchYoutubeSongs, uploadCSV, removeSong, updateMedia } from "../../api/youtubeSongs";
import _ from "lodash"

import { Button, CircularProgress, List } from "@material-ui/core";
import CreateYouTubeItemDialog from "./CreateYoutubeItemDialog";
import { useStyles } from "../common/styles";

import Table, { NumberRangeColumnFilter, GlobalFilterComponent } from "../Table/Table";
import { ConfirmationDialogRaw } from "../ConfirmDelete"

import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';

import EditableField from "../EditableField";
import { TextField } from "@material-ui/core";

function Content({ isLoading, isFetchFailed, searchQuery, isLoadingSearch, filteredPlaylists, songs, fetchYoutubeSongs, addNewItemMode,
    setAddNewItemMode, deleteSongFromState, addSongToState, editSourceValidation }) {


    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [selectedSong, setSelectedSong] = React.useState(null);
    const [mediaLabel, setMediaLabel] = React.useState("");
    const [textFieldType, setTextFieldType] = React.useState("");

    const handleClose = async (shouldDelete = false) => {
        setOpen(false);

        if (shouldDelete) {
            const songIndex = _.findIndex(songs, { id: selectedSong })
            const song = songs[songIndex]
            try {
                let res = await removeSong(selectedSong);
                console.log("delete", res)
                //fetchYoutubeSongs()
                deleteSongFromState(selectedSong)
            } catch (e) {
                console.error("shouldDelete song error", e)
                alert("Error Removing song")
                addSongToState(song)
            }
        }
        setSelectedSong(null)
        setMessage("")
    };

    const updateField = async ({ id, key, value }) => {
        console.log("updating media", { id, key, value })
        let payload = { id }
        payload[key] = value
        let res = await updateMedia(payload)
        console.log("updateMediaRes", res)
    }

    function renderMediaInput({ value, setValue }) {
        return <TextField
            id="outlined-dense"
            label={mediaLabel}
            margin="dense"
            variant="outlined"
            value={value === 'add' ? "" : value}
            onChange={event => {
                setValue(event.target.value);
            }}
            type={textFieldType}
        />;
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Media Type',
                accessor: 'resourceType',
                filter: 'fuzzyText',
                Cell: ({ row, value }) => {
                    setMediaLabel("Type")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "resourceType", value })
                        }} />
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                filter: 'fuzzyText',
                Cell: ({ row, value }) => {
                    setMediaLabel("Name")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "name", value })
                        }} />
                }
            },
            {
                Header: 'source (Youtube Id)',
                accessor: 'source',
                filter: 'fuzzyText',
                Cell: ({ row, value }) => {
                    setMediaLabel("YT ID")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            if (editSourceValidation(value)) {
                                window.alert("media with the same source already exist in the system")
                                return false;
                            } else {
                                updateField({ id: row.original.id, key: "source", value })
                            }
                        }} />
                }
            },
            {
                Header: 'Energy',
                accessor: 'energy',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                Cell: ({ row, value }) => {
                    setMediaLabel("Energy")
                    setTextFieldType("number")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "energy", value })
                        }} />
                }
            },
            {
                Header: 'Valence',
                accessor: 'valence',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                Cell: ({ row, value }) => {
                    setMediaLabel("Valence")
                    setTextFieldType("number")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "valence", value })
                        }} />
                }
            },
            {
                Header: 'Genres',
                id: "genres",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => arrayAccessor(originalRow, rowIndex, "Genres"),
                Cell: ({ row, value }) => {
                    setMediaLabel("Genres")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "genres", value })
                        }} />
                }
            },
            {
                Header: 'Languages',
                id: "lang",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => arrayAccessor(originalRow, rowIndex, "Languages"),
                Cell: ({ row, value }) => {
                    setMediaLabel("Languages")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "lang", value })
                        }} />
                }
            },
            {
                Header: 'Artists',
                id: "artists",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => arrayAccessor(originalRow, rowIndex, "Artists"),
                Cell: ({ row, value }) => {
                    setMediaLabel("Artists")
                    setTextFieldType("text")
                    return <EditableField
                        value={value ? value : 'add'}
                        renderInput={renderMediaInput}
                        onSave={(value) => {
                            updateField({ id: row.original.id, key: "artists", value })
                        }} />
                }
            },
            {
                id: "actions",
                Header: "",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id, name: originalRow.name }),
                filter: null,
                Filter: () => null,
                Cell: ({ value }) => (
                    <IconButton color="primary" aria-label="delete" component="span" onClick={() => {
                        console.log("delete", value.id)
                        setMessage(`Are you sure you want to delete "${value.name}"?`)
                        setOpen(true)
                        setSelectedSong(value.id)
                    }}>
                        <Delete />
                    </IconButton>
                )
            }
        ],
        []
    )

    const tableData = React.useMemo(
        () => songs,
        [songs]
    )

    const classes = useStyles();
    return (
        <div className={classes.root}>

            <Button
                variant="contained"
                component="span"
                color={addNewItemMode ? "secondary" : "primary"}
                onClick={() => {
                    setAddNewItemMode(!addNewItemMode)
                }}
            >
                {addNewItemMode ? "Cancel" : "Upload CSV"}
            </Button>
            {addNewItemMode ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    style={{ marginLeft: 16 }}
                >
                    Save
                </Button>
            ) : null}
            {isLoading ? (
                <CircularProgress />
            ) : isFetchFailed ? (
                "Error"
            ) : (
                <Table
                    columns={columns}
                    data={tableData}
                />
            )}

            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                message={message}
                title={"Confirm Delete"}
            />
        </div>
    );
}

const arrayAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => item.name) : [];
    let join = map.join();
    return join
}



class YoutubeSongs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewItemMode: false,
            uploading: false,
            uploadError: false
        };
    }

    componentDidMount() {
        if (!this.props.songs || this.props.songs.length === 0) {
            this.props.fetchYoutubeSongs();
        }
    }

    componentWillUnmount() {
        if (this.searchInputTimeoutHandler) {
            clearTimeout(this.searchInputTimeoutHandler);
        }
    }

    isDuplicateSource = (source) => {
        const { songs } = this.props;
        console.log("isDuplicateSource", songs)
        if (_.find(songs, (s) => {
            return s.source.toLowerCase() === source.toLowerCase()
        })) {
            return true
        }
    }

    render() {

        const {
            songs,
            filteredPlaylists,
            fetchYoutubeSongs,
            setYoutubePlaylistsSearchQuery,
            deleteSongFromState,
            addSongToState
        } = this.props;

        const { uploading, uploadError } = this.state;

        return (
            <div style={{ flex: 1, height: "100%", width: "100%" }}>
                <Content {...this.props}
                    addNewItemMode={this.state.addNewItemMode}
                    setAddNewItemMode={(addNewItemMode) => { this.setState({ addNewItemMode }) }}
                    deleteSongFromState={deleteSongFromState} addSongToState={addSongToState}
                    editSourceValidation={this.isDuplicateSource} />
                <CreateYouTubeItemDialog
                    open={this.state.addNewItemMode}
                    handleClose={() => {
                        this.setState({
                            addNewItemMode: false
                        });
                    }}
                    onShouldFetchItems={fetchYoutubeSongs}
                    formProps={this.props}
                    onSubmit={(file) => {
                        this.setState({ uploading: true })
                        uploadCSV(file).then(data => {
                            console.log(data)
                            this.setState({
                                addNewItemMode: false,
                                uploadError: false,
                                uploading: false
                            });
                        })
                            .catch(error => {
                                console.log(error)
                                this.setState({
                                    uploadError: true,
                                    uploading: false
                                });
                            })
                    }}
                    uploading={uploading}
                    uploadError={uploadError}
                />
            </div>
        );
    }
}

YoutubeSongs.propTypes = {};

YoutubeSongs.defaultProps = {};

const mapStateToProps = ({ youtubeSongs }) => ({
    songs: youtubeSongs.songs,
    filteredPlaylists: youtubeSongs.filteredPlaylists,
    isLoading: youtubeSongs.isLoading || youtubeSongs.isLoadingSearch,
    isFetchFailed: youtubeSongs.isFetchFailed,
    searchQuery: youtubeSongs.searchQuery,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchYoutubeSongs,
            setYoutubePlaylistsSearchQuery,
            deleteSongFromState,
            addSongToState
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(YoutubeSongs);
