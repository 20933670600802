import { apiRoutes, getAuthHeader } from '../api';

export const configService = {
  getAllConfigs: async () => {
    try {
      const response = await fetch(apiRoutes.getAllConfigs, {
        method: 'GET',
        headers: getAuthHeader()
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  getConfigByKey: async (key) => {
    try {
      const response = await fetch(apiRoutes.getConfigByKey(key), {
        method: 'GET',
        headers: getAuthHeader()
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  createConfig: async (configData) => {
    try {
        let headers = getAuthHeader();
        headers.append("Content-Type", "application/json")
      const response = await fetch(apiRoutes.createConfig, {
        method: 'POST',
        headers,
        body: JSON.stringify(configData)
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  updateConfig: async (key, configData) => {
    try {
        let headers = getAuthHeader();
        headers.append("Content-Type", "application/json")
      const response = await fetch(apiRoutes.updateConfig(key), {
        method: 'PUT',
        headers,
        body: JSON.stringify(configData)
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  },

  deleteConfig: async (key) => {
    try {
      const response = await fetch(apiRoutes.deleteConfig(key), {
        method: 'DELETE',
        headers: getAuthHeader()
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
}; 