export const FIRESTORE_COLLECTION_NAMES = {
    therapyContent: "therapyContent",
    therapyContentAuthors: "therapyContentAuthors",
    youtubePlaylists: "youTubePlayLists",
    youTubeSongs: "songs"
};

export const LANGUAGES_MAP = {
    english: {
      code: "en",
      title: "English",
      therapyContentLanguage: "en",
    },
    hebrew: {
      code: "he",
      title: "עברית",
      therapyContentLanguage: "he",
    },
    arabic: {
      code: "ar",
      title: "عربيه",
      therapyContentLanguage: "en",
    },
    russian: {
      code: "ru",
      title: "Pусский",
      therapyContentLanguage: "en",
    },
    spanish: {
      code: "es",
      title: "Español",
      therapyContentLanguage: "en",
    },
    japanese: {
      code: "ja",
      title: "日本人 (Japanese)",
      therapyContentLanguage: "en",
    },
    simplifiedChinese: {
      code: "zh-Hans",
      title: "中文 (Simplified Chinese)",
      therapyContentLanguage: "en",
    },
    traditionalChineseTaiwan: {
      code: "zh-TW-Hant",
      title: "中文 (Traditional Chinese)",
      therapyContentLanguage: "en",
    },
  };

export const DYNAMIC_LINK_DOMAIN = "https://iamsolo.page.link";

export const dynamicLinkToHouse = (houseKey) => `https://imsolo.ai/?house=${houseKey}`;

