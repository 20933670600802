import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import placeholder from "lodash/fp/placeholder";

class EditableField extends Component {

    constructor(props) {
        super();
        this.state = {
            hover: false,
            focused: false,
            editValue: props.value,
        }
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover })
    }

    setEditMode = () => {
        if (!this.state.focused) {
            this.setState({ focused: true })
            this.props.onEdit && this.props.onEdit()
        }
    }

    cancel = () => {
        this.setState({
            focused: false,
            editValue: this.props.value,
            hover: false,
        })
    }

    save = () => {
        const { editValue } = this.state
        let res = this.props.onSave(editValue)
        if (res === false) {
            this.setState({
                editValue: this.props.value,
            })
        }
        this.setState({
            focused: false,
            hover: false,
        })
    }

    renderInput = () => {
        const { editValue } = this.state
        const {placeholder} = this.props;
        return <input placeholder={placeholder} value={editValue} onChange={(e) => {
            this.setState({ editValue: e.target.value })
        }} />
    }

    render() {
        const { editValue, hover, focused } = this.state
        const { renderInput, onSave, fieldMaxWidth, placeholder, boolean } = this.props;

        let displayValue = editValue || placeholder
        if(boolean){
            displayValue = editValue ? "true" : "false"
        }

        const input = focused ? renderInput ? renderInput({ onSave: this.save, value: editValue, setValue: (value) => { this.setState({ editValue: value }) } }) : this.renderInput() : null;
        return (
            <div onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={{ display: "flex", cursor: "text", minWidth: 50, minHeight: 50 }}>
                {!focused && (<span onClick={this.setEditMode}
                    style={fieldMaxWidth ? { maxWidth: fieldMaxWidth } : {}}>{displayValue}</span>)}
                { hover && !focused && <EditIcon onClick={this.setEditMode} style={{ cursor: "pointer" }} />
                }
                { focused && (<CloseIcon onClick={this.cancel} style={{ cursor: "pointer" }} />)}
                { input}
                { focused && (<DoneIcon onClick={this.save} style={{ cursor: "pointer" }} />)}
            </div >
        );
    }
}

EditableField.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onSave: PropTypes.func,
    disabled: PropTypes.bool,
    renderInput: PropTypes.func,
    onEdit: PropTypes.func,
    placeholder: PropTypes.string,
    boolean: PropTypes.bool,
};

EditableField.defaultProps = {
    boolean: false,
}

export default EditableField;
