import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";

import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import {
    Container,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Switch,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Divider,
    OutlinedInput,
    Select,
    Grid,
    Card,
    Chip,
    CardContent,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormGroup,
    Toolbar,
    IconButton,
    DialogContentText,
    DialogContent,
    DialogTitle,
    DialogActions,
    LinearProgress,
    InputBase
} from "@material-ui/core";
import { LANGUAGES_MAP } from "../../api/constants";
import { fade, makeStyles } from "@material-ui/core/styles";

export const HOUSE_TYPES = {
    business: "business",
    private: "private",
    upload: "upload",
    realtime_video: "realtime_video",
    checkup: "checkup"
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

export function HouseName({ value, setValue }) {
    return <TextField
        id="outlined-dense"
        label="House Name"
        margin="dense"
        variant="outlined"
        value={value}
        onChange={event => {
            setValue(event.target.value);
        }}
    />;
}

export function HouseCountry({ value, setValue }) {
    return <TextField
        id="outlined-dense"
        label="Country"
        margin="dense"
        variant="outlined"
        value={value}
        onChange={event => {
            setValue(event.target.value);
        }}
    />;
}

export function HouseType({ value, setValue }) {
    return <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-simple">
            House Type
        </InputLabel>
        <Select
            value={value}
            onChange={event => {
                setValue(event.target.value);
            }}
            input={
                <OutlinedInput
                    name="contentLanguage"
                    id="outlined-content-hose-type-simple"
                />
            }
        >
            {Object.keys(HOUSE_TYPES).map(type => {
                return (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                );
            })}
        </Select>
    </FormControl>;
}

export function BooleanField({ value, setValue }) {
    return <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-simple">
           Active
        </InputLabel>
        <Select
            value={value}
            onChange={event => {
                setValue(event.target.value);
            }}
            input={
                <OutlinedInput
                    name="contentLanguage"
                    id="outlined-content-hose-type-simple"
                />
            }
        >
            <MenuItem key={"true"} value={true}>
                {"true"}
            </MenuItem>
            <MenuItem key={"false"} value={false}>
                {"false"}
            </MenuItem>
        </Select>
    </FormControl>;
}

export function HouseConfigVersion({ value, setValue }) {
    return <TextField
        id="outlined-dense"
        label="House Config Version"
        margin="dense"
        variant="outlined"
        value={value}
        onChange={event => {
            setValue(event.target.value);
        }}
    />;
}

export function HouseMaxResidents({ value, setValue }) {
    return <TextField
        id="outlined-dense"
        label="Max Residents"
        type={"number"}
        margin="dense"
        variant="outlined"
        value={value}
        onChange={event => {
            setValue(event.target.value);
        }}
    />;
}

export function HouseOrg({ organizations, value, setValue }) {
    return <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-age-simple">
            ORG
        </InputLabel>
        <Select
            value={value}
            onChange={event => {
                setValue(event.target.value);
            }}
            input={
                <OutlinedInput
                    name="houseOrg"
                    id="outlined-content-hose-org-simple"
                />
            }
        >

            {organizations.map(org => {
                return (
                    <MenuItem key={org.id} value={org.id}>
                        {org.name}
                    </MenuItem>
                );
            })}

        </Select>
    </FormControl>;
}


export function HouseAdmin({ adminName, setAdminName, adminEmail, setAdminEmail }) {
    return <div>
        <TextField
            id="outlined-dense"
            label="Admin Name"
            margin="dense"
            variant="outlined"
            value={adminName}
            onChange={event => {
                setAdminName(event.target.value);
            }}
        />
        <TextField
            id="outlined-dense"
            label="Admin Email"
            margin="dense"
            variant="outlined"
            value={adminEmail}
            onChange={event => {
                setAdminEmail(event.target.value);
            }}
        />
    </div>
}


function HouseForm({ onSubmit, uploading, uploadError, createdHouse, handleClose, isDuplicateName, organizations }) {

    const classes = useStyles();
    const [name, setName] = React.useState("");
    const [type, setType] = React.useState(HOUSE_TYPES.business);
    const [maxResidents, setMaxResidents] = React.useState(50);
    const [error, setError] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [copySuccess, setCopySuccess] = React.useState("");
    const [org, setOrg] = React.useState("");
    const [adminName, setAdminName] = React.useState("");
    const [adminEmail, setAdminEmail] = React.useState("");

    let textArea = React.createRef()

    //  const [customHouseKey, setCustomHouseKey] = React.useState();

    const validate = () => {
        let isValid = true;
        if (!name) {
            isValid = false;
            setError("name is required")
        }

        if (isDuplicateName(name)) {
            isValid = false;
            setError("name already exist")
        }

        if ((adminName && !adminEmail) || (!adminName && adminEmail)) {
            setError("name and email required to add admin")
        }

        if (!validateEmail(adminEmail)) {
            setError("invalid email")
        }

        return isValid;
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!')
    };

    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <form
                        style={{ display: "flex", flexWrap: "wrap" }}
                        noValidate
                        autoComplete="off"
                    >

                        {HouseName({ value: name, setValue: setName })}

                        {HouseCountry({ value: country, setValue: setCountry })}

                        {HouseType({ classes, value: type, setValue: setType })}

                        {HouseMaxResidents({ value: maxResidents, setValue: setMaxResidents })}

                        {HouseOrg({ organizations, value: org, setValue: setOrg })}

                        {HouseAdmin({ adminName, setAdminName, adminEmail, setAdminEmail })}
                    </form>

                )}

                {error}
                {uploadError && (<div style={{ color: "red" }}>error creating house</div>)}
                {createdHouse ? (
                    <Fragment>
                        <div>
                            <div>Created House Key:</div>
                            <textarea
                                onClick={copyToClipboard}
                                ref={(ref) => textArea = ref}
                                value={createdHouse.house ? createdHouse.house.key : null}
                            />
                        </div>

                        {createdHouse.house && createdHouse.house.password ?
                            <div>
                                <div>Created Admin Password:</div>
                                <textarea
                                    onClick={copyToClipboard}
                                    ref={(ref) => textArea = ref}
                                    value={createdHouse.house.password}
                                />
                            </div> : null}
                    </Fragment>
                ) : null}

                {copySuccess}
            </Paper>


            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={uploading}
                onClick={() => {
                    if (!validate()) {
                        return;
                    }
                    setError("")
                    onSubmit({ name, maxResidents, type, country, org, adminName, adminEmail })
                }}
            >
                Submit
            </Button>

        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateHouseDialog({
    open,
    handleClose,
    formProps,
    onSubmit,
    uploading,
    uploadError,
    createdHouse,
    isDuplicateName,
    organizations
}) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <HouseForm onSubmit={onSubmit} uploading={uploading} uploadError={uploadError} handleClose={handleClose}
                createdHouse={createdHouse} isDuplicateName={isDuplicateName} organizations={organizations} />
        </Dialog>
    );
}

