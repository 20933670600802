import { firestore } from "./firebase";
import { FIRESTORE_COLLECTION_NAMES } from "./constants";
import { decodeQueryDataFromUrl } from "../utils";
import {
    FETCH_YOUTUBE_SONGS,
    FETCH_YOUTUBE_SONGS_FAILED,
    FETCH_YOUTUBE_SONGS_REQUESTED,
    UPLOAD_YOUTUBE_SONGS_REQUESTED,
    UPLOAD_YOUTUBE_SONGS_SUCCESS,
    UPLOAD_YOUTUBE_SONGS_FAILED,
    getDefaultSong
} from "../modules/youtubeSongs";

import axios from "axios"

import { api, apiRoutes, getAuthHeader } from "./index"

export const uploadCSV = (file) => {


    const data = new FormData()
    data.append('file', file)



    return fetch(apiRoutes.uploadCSV, {
        method: 'POST',
        // mode: 'no-cors',
        body: data,
        headers: getAuthHeader()
    })
        .then(response => {
            return response.json()
        })


}


export const fetchYoutubeSongs = () => {
    return dispatch => {
        dispatch({
            type: FETCH_YOUTUBE_SONGS_REQUESTED
        });

        fetchAllYoutubeSongs()
            .then(results => {

                console.log("results", results);

                let songs = results && results.data ? results.data : [];
                /* results.forEach(document => {
                     songs.push(document.data());
                 });*/
                console.log("SONGS", songs);
                dispatch({
                    type: FETCH_YOUTUBE_SONGS,
                    payload: {
                        songs: songs
                    }
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: FETCH_YOUTUBE_SONGS_FAILED
                });
            });
    };
};

async function fetchAllYoutubeSongs() {

    return fetch(apiRoutes.getAllSongs, { headers: getAuthHeader() }).then((response) => response.json())
    /* return firestore()
         .collection(FIRESTORE_COLLECTION_NAMES.youTubeSongs)
         .get();*/
}

export async function createSong(url, name, style, language, period, energy, thumbnailUrl, why) {
    const newDocRef = firestore()
        .collection(FIRESTORE_COLLECTION_NAMES.youTubeSongs)
        .doc();

    const data = getDefaultSong()
    data.url = url;
    data.ref = newDocRef.id;
    data.name = name;
    data.period = period
    data.energy = energy;
    data.language = language;
    data.style = style;
    data.createdAt = Date.now().toString()
    data.thumbnailUrl = thumbnailUrl;
    data.why = why;

    await newDocRef.set(data);
}

export async function updateSong(ref, { url, name, style, language, period, energy, thumbnailUrl, why }) {
    if (!ref) {
        throw new Error("can't update empty item ref");
    }
    // console.log("updating song", ref, {url, name, style, language, period, energy, thumbnailUrl, why})
    await firestore()
        .collection(FIRESTORE_COLLECTION_NAMES.youTubeSongs)
        .doc(ref)
        .update({ url, name, style, language, period, energy, thumbnailUrl, why })

    return { url, name, style, language, period, energy, thumbnailUrl, why };

}

export const updateMedia = ({ id, resourceType, name, source, energy, valence, genres, lang, artists }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.media(id), {
        method: 'PUT',
        // mode: 'no-cors',
        body: JSON.stringify({ resourceType, name, source, energy, valence, genres, lang, artists }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export async function removeSong(songRef) {
    try {
        const response = await fetch(apiRoutes.deleteSong(songRef), { method: 'DELETE', headers: getAuthHeader() })
        const json = await response.json();
        return json;
    } catch (e) {
        console.error(e)
        return false
    }

}

const getVideoIdFromURL = (url) => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        //error
        return ""
    }
}

let _parseJSON = function (response) {
    return response.text().then(function (text) {
        return text ? JSON.parse(text) : {}
    })
}

export async function getYoutubeSongMetadataUrl(videoUrl) {

    const apikey = "AIzaSyCc5bvmTnx-TdKZ9lfakbULXYL-vK0rG_0";
    const videoId = getVideoIdFromURL(videoUrl);

    //return axios.get(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apikey}`)
    //https://noembed.com/embed?url=https://www.youtube.com/watch?v=dQw4w9WgXcQ
    return axios.get(`https://noembed.com/embed?url=${videoUrl}`)
}

