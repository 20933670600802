import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const AlertDialog = ({open, title, message, negativeButtonTitle, negativeButtonCallback, handleClose, positiveButtonCallback, positiveButtonTitle}) => {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {negativeButtonTitle && negativeButtonCallback ? <Button
                        onClick={negativeButtonCallback || handleClose}
                        color="secondary"
                        autoFocus
                    >
                        {negativeButtonTitle}
                    </Button> : null}
                    <Button
                        onClick={positiveButtonCallback || handleClose}
                        color="primary"
                        autoFocus
                    >
                        {positiveButtonTitle || "OK"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;