import { flow, set, get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import _ from "lodash"

export const FETCH_SDK_ORGANIZATIONS_REQUESTED = "sdkOrganizations/FETCH_SDK_ORGANIZATIONS_REQUESTED";
export const FETCH_SDK_ORGANIZATIONS_SUCCESS = "sdkOrganizations/FETCH_SDK_ORGANIZATIONS_SUCCESS";
export const FETCH_SDK_ORGANIZATIONS_FAILED = "sdkOrganizations/FETCH_SDK_ORGANIZATIONS_FAILED";

export const CREATE_SDK_ORGANIZATION_REQUESTED = "sdkOrganizations/CREATE_SDK_ORGANIZATION_REQUESTED";
export const CREATE_SDK_ORGANIZATION_SUCCESS = "sdkOrganizations/CREATE_SDK_ORGANIZATION_SUCCESS";
export const CREATE_SDK_ORGANIZATION_FAILED = "sdkOrganizations/CREATE_SDK_ORGANIZATION_FAILED";

export const ADD_SDK_ORGANIZATION = "sdkOrganizations/ADD_SDK_ORGANIZATION";
export const DELETE_SDK_ORGANIZATION = "sdkOrganizations/DELETE_SDK_ORGANIZATION";

export const UPDATE_ORG = "sdkOrganizations/UPDATE_ORG";

export const UPDATE_ORG_PRICING_TIER = "sdkOrganizations/UPDATE_ORG_PRICING_TIER";

const initialState = {
    organizations: [],
    isLoading: false,
    isFetchFailed: false,
    filtered: [],
    uploading: false,
    uploadError: false
};

const addOrganizationHelper = (organizations, organization) => {
    let clonedOrganizations = _.cloneDeep(organizations)
    clonedOrganizations.push(organization)
    return clonedOrganizations
}

const deleteOrganizationHelper = (organizations, id) => {
    return organizations.filter(o => o.id !== id)
}

const updateOrgHelper = (organizations, id, payload) => {
    return organizations.map(o => {
        if (o.id === id) {
            o = { ...o, ...payload }
        }
        return o
    })
}

const updateOrgPricingTierHelper = (organizations, id, tier) => {
    return organizations.map(o => {
        if (o.id === id) {
            o.OrgPricings = [tier]
        }
        return o
    })
}

export default handleActions({
    [FETCH_SDK_ORGANIZATIONS_REQUESTED]: (state, action) => flow([
        set("isLoading", true),
        set("isFetchFailed", false)
    ])(state),

    [FETCH_SDK_ORGANIZATIONS_SUCCESS]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", false),
        set("organizations", action.payload.orgs)
    ])(state),

    [FETCH_SDK_ORGANIZATIONS_FAILED]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", true)
    ])(state),

    [CREATE_SDK_ORGANIZATION_REQUESTED]: (state, action) => flow([
        set("uploading", true),
        set("uploadError", false)
    ])(state),

    [CREATE_SDK_ORGANIZATION_SUCCESS]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", false),
    ])(state),

    [CREATE_SDK_ORGANIZATION_FAILED]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", true)
    ])(state),

    [ADD_SDK_ORGANIZATION]: (state, action) => flow([
        set("organizations", addOrganizationHelper(state.organizations, action.payload.organization)),
    ])(state),

    [DELETE_SDK_ORGANIZATION]: (state, action) => flow([
        set("organizations", deleteOrganizationHelper(state.organizations, action.payload.id)),
    ])(state),

    [UPDATE_ORG_PRICING_TIER]: (state, action) => flow([
        set("organizations", updateOrgPricingTierHelper(state.organizations, action.payload.id, action.payload.tier)),
    ])(state),

    [UPDATE_ORG]: (state, action) => flow([
        set("organizations", updateOrgHelper(state.organizations, action.payload.id, action.payload)),
    ])(state),


}, initialState);

export const addOrgToState = (org) => {
    return dispatch => {
        dispatch({
            type: ADD_SDK_ORGANIZATION,
            payload: {
                organization: org
            }
        });
    }
}

export const updateOrgInState = (id, payload) => {
    return dispatch => {
        dispatch({
            type: UPDATE_ORG,
            payload: {
                id,
                ...payload
            }
        });
    }
}

export const deleteOrgFromState = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_SDK_ORGANIZATION,
            payload: {
                id
            }
        });
    }
}

export const updateOrgPricingTier = (id, tier) => {
    return dispatch => {
        dispatch({
            type: UPDATE_ORG_PRICING_TIER,
            payload: {
                id,
                tier
            }
        });
    }
}



