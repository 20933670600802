import { useStyles } from "./styles";
import React from "react";
import {
    removePlaylist, setPlaylistEnergy,
    setPlaylistLanguage,
    setPlaylistPeriod,
    setPlaylistStyle,
    setPlaylistUrl
} from "../../api/youtubePlaylists";
import {
    Button, CircularProgress,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary, FormControl, Grid, InputLabel,
    ListItemText, MenuItem, OutlinedInput,
    Paper, Radio, Select, TextField
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { decodeQueryDataFromUrl, stringToHexColor } from "../../utils";
import Typography from "@material-ui/core/Typography";
import { ENERGIES, PERIODS } from "../../api/therapyContent";
import AlertDialog from "./AlertDialog";


function YoutubeListItem(data) {
    const classes = useStyles();

    const [item, setItem] = React.useState(data.item);

    const [newTagInputValue, setNewTagInputValue] = React.useState("");

    const [isRemovingItem, setIsRemovingItem] = React.useState(false);

    const _setItem = newItem => setItem(Object.assign({}, item, newItem));

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const [alertDialogState, setAlertDialogState] = React.useState({
        open: false,
    });

    const [languageInputValue, setLanguageInputValue] = React.useState(item.languages ? item.languages[0] : "");
    const [styleInputValue, setStyleInputValue] = React.useState(item.style ? item.style[0] : "");
    const [urlInputValue, setUrlInputValue] = React.useState(item.url || "");

    const [styleOrLanguageInputToShow, setStyleOrLanguageInputToShow] = React.useState(item.style ? "style" : item.languages ? "language" : "");

    function handleDeleteItemForeverClick() {
        setIsRemovingItem(true);
        setAlertDialogState({
            open: true,
            title: "Removing playlist",
            message: "Please wait..."
        });
        removePlaylist(item.ref)
            .then(() => {
                data.props.fetchYoutubePlaylists();
            })
            .catch(error => console.log(error))
            .finally(() => {
                setIsRemovingItem(false);
                setAlertDialogState({
                    open: false,
                })
            });
    }

    return (
        <Paper style={{ marginBottom: 8, width: "100%" }} key={item.ref}>
            <ExpansionPanel style={{ width: "100%" }}>
                <ExpansionPanelSummary
                    style={{ width: "100%" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <ListItemText primary={item.name} secondary={item.url} />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                backgroundColor: item.period
                                    ? stringToHexColor(item.period)
                                    : "#f4f4f4",
                                borderRadius: 4,
                                padding: 8,
                                marginRight: 8
                            }}
                        >
                            <Typography>{item.period}</Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                backgroundColor: item.energy
                                    ? stringToHexColor(item.energy)
                                    : "#f4f4f4",
                                borderRadius: 4,
                                padding: 8,
                                marginRight: 8
                            }}
                        >
                            <Typography>{item.energy}</Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                backgroundColor: item.style
                                    ? stringToHexColor(item.style[0])
                                    : item.languages
                                        ? stringToHexColor(item.languages[0])
                                        : "#f4f4f4",
                                borderRadius: 4,
                                padding: 8,
                                marginRight: 8
                            }}
                        >
                            <Typography>
                                {item.style
                                    ? item.style[0]
                                    : item.languages
                                        ? item.languages[0]
                                        : "-"}
                            </Typography>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    style={{ flexDirection: "column", display: "flex" }}
                >
                    <Divider />
                    <Grid container spacing={3} style={{ marginTop: 8 }}>
                        <Grid item xs={12}>
                            <form
                                style={{ display: "flex", flexWrap: "wrap" }}
                                noValidate
                                autoComplete="off"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "center"
                                    }}
                                >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="URL"
                                        multiline
                                        rows="4"
                                        defaultValue={item.url}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={event => {
                                            setUrlInputValue(event.target.value);
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        style={{ width: "100%", marginTop: 0 }}
                                        disabled={
                                            item.url == urlInputValue ||
                                            !urlInputValue ||
                                            !decodeQueryDataFromUrl(urlInputValue).list
                                        }
                                        onClick={() => {
                                            const oldUrl = item.url;
                                            _setItem({ url: urlInputValue });
                                            setPlaylistUrl(item.ref, urlInputValue).catch(error => {
                                                console.log(error);
                                                _setItem({ url: oldUrl });
                                            });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div>
                                    <Radio
                                        checked={styleOrLanguageInputToShow === "language"}
                                        onChange={(event) => {
                                            setStyleOrLanguageInputToShow(event.target.value);
                                        }}
                                        value="language"
                                        name="radio-button-language"
                                        inputProps={{ "aria-label": "Language" }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "center",
                                    }}
                                >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Language"
                                        multiline
                                        rows="4"
                                        defaultValue={item.languages ? item.languages[0] : ""}
                                        className={classes.textField}
                                        disabled={styleOrLanguageInputToShow !== "language"}
                                        margin="normal"
                                        variant="outlined"
                                        value={languageInputValue}
                                        onChange={event => {
                                            setLanguageInputValue(event.target.value);
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        style={{ width: "100%", marginTop: 0 }}
                                        disabled={
                                            styleOrLanguageInputToShow !== "language" ||
                                            !languageInputValue ||
                                            (item.languages &&
                                                item.languages[0] === languageInputValue)
                                        }
                                        onClick={() => {
                                            const oldValue = item.languages;
                                            _setItem({ languages: [languageInputValue] });
                                            setPlaylistLanguage(item.ref, languageInputValue, item)
                                                .then(updatedData => {
                                                    _setItem(updatedData);
                                                    setStyleInputValue("");
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                    _setItem({ languages: oldValue });
                                                });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div>
                                    <Radio
                                        checked={styleOrLanguageInputToShow === "style"}
                                        onChange={(event) => {
                                            setStyleOrLanguageInputToShow(event.target.value);
                                        }}
                                        value="style"
                                        name="radio-button-style"
                                        inputProps={{ "aria-label": "Style" }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "center",
                                    }}
                                >
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Style"
                                        multiline
                                        rows="4"
                                        defaultValue={item.style ? item.style[0] : ""}
                                        className={classes.textField}
                                        disabled={styleOrLanguageInputToShow !== "style"}
                                        margin="normal"
                                        variant="outlined"
                                        value={styleInputValue}
                                        onChange={event => {
                                            setStyleInputValue(event.target.value);
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        style={{ width: "100%", marginTop: 0 }}
                                        disabled={
                                            styleOrLanguageInputToShow !== "style" ||
                                            !styleInputValue ||
                                            (item.style && item.style[0] === styleInputValue)
                                        }
                                        onClick={() => {
                                            const oldValue = item.style;
                                            _setItem({ style: [styleInputValue] });
                                            setPlaylistStyle(item.ref, styleInputValue, item)
                                                .then(updatedData => {
                                                    _setItem(updatedData);
                                                    setLanguageInputValue("");
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                    _setItem({ style: oldValue });
                                                });
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                                        Period
                                    </InputLabel>
                                    <Select
                                        value={item.period}
                                        onChange={event => {
                                            const newValue = event.target.value;
                                            setPlaylistPeriod(item.ref, newValue, item)
                                                .then(updatedData => {
                                                    _setItem(updatedData);
                                                })
                                                .catch(error => console.log(error));
                                        }}
                                        input={
                                            <OutlinedInput
                                                labelWidth={labelWidth}
                                                name="period"
                                                id="outlined-content-language-simple"
                                            />
                                        }
                                    >
                                        {PERIODS.map(value => {
                                            return (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                                        Energy
                                    </InputLabel>
                                    <Select
                                        value={item.energy}
                                        onChange={event => {
                                            const newValue = event.target.value;
                                            setPlaylistEnergy(item.ref, newValue, item)
                                                .then(updatedData => {
                                                    _setItem(updatedData);
                                                })
                                                .catch(error => console.log(error));
                                        }}
                                        input={
                                            <OutlinedInput
                                                labelWidth={labelWidth}
                                                name="energy"
                                                id="outlined-energy-simple"
                                            />
                                        }
                                    >
                                        {ENERGIES.map(value => {
                                            return (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </form>
                        </Grid>
                    </Grid>
                    <div style={{ height: 32 }} />
                    <Divider />
                    <div style={{ height: 16 }} />
                    <div>
                        <Button
                            color="secondary"
                            variant="secondary"
                            onClick={() => {
                                setAlertDialogState({
                                    open: true,
                                    title: "Are you sure?",
                                    message:
                                        "This will remove the playlist permanently. In order to do that - close this dialog and double-click the button",
                                    positiveButtonTitle: "Cancel",
                                    negativeButtonTitle: "Delete forever",
                                    negativeButtonCallback: handleDeleteItemForeverClick
                                });
                            }}
                            disabled={isRemovingItem}
                        >
                            Delete Forever
                            {isRemovingItem ? (
                                <CircularProgress size={20} style={{ marginLeft: 16 }} />
                            ) : null}
                        </Button>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <AlertDialog
                open={alertDialogState.open}
                handleClose={() => setAlertDialogState({ open: false })}
                title={alertDialogState.title}
                message={alertDialogState.message}
                positiveButtonTitle={alertDialogState.positiveButtonTitle}
                positiveButtonCallback={alertDialogState.positiveButtonCallback}
                negativeButtonTitle={alertDialogState.negativeButtonTitle}
                negativeButtonCallback={alertDialogState.negativeButtonCallback}
            />
        </Paper>
    );
}

export default YoutubeListItem