import { configService } from '../services/configService';

// Action Types
const FETCH_CONFIGS_REQUEST = 'configurations/FETCH_CONFIGS_REQUEST';
const FETCH_CONFIGS_SUCCESS = 'configurations/FETCH_CONFIGS_SUCCESS';
const FETCH_CONFIGS_FAILURE = 'configurations/FETCH_CONFIGS_FAILURE';

const CREATE_CONFIG_REQUEST = 'configurations/CREATE_CONFIG_REQUEST';
const CREATE_CONFIG_SUCCESS = 'configurations/CREATE_CONFIG_SUCCESS';
const CREATE_CONFIG_FAILURE = 'configurations/CREATE_CONFIG_FAILURE';

const UPDATE_CONFIG_REQUEST = 'configurations/UPDATE_CONFIG_REQUEST';
const UPDATE_CONFIG_SUCCESS = 'configurations/UPDATE_CONFIG_SUCCESS';
const UPDATE_CONFIG_FAILURE = 'configurations/UPDATE_CONFIG_FAILURE';

const DELETE_CONFIG_REQUEST = 'configurations/DELETE_CONFIG_REQUEST';
const DELETE_CONFIG_SUCCESS = 'configurations/DELETE_CONFIG_SUCCESS';
const DELETE_CONFIG_FAILURE = 'configurations/DELETE_CONFIG_FAILURE';

// Initial State
const initialState = {
  configs: [],
  loading: false,
  error: null,
  actionInProgress: false,
  actionError: null
};

// Reducer
export default function configurationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        configs: action.payload
      };
    case FETCH_CONFIGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_CONFIG_REQUEST:
    case UPDATE_CONFIG_REQUEST:
    case DELETE_CONFIG_REQUEST:
      return {
        ...state,
        actionInProgress: true,
        actionError: null
      };
    case CREATE_CONFIG_SUCCESS:
      return {
        ...state,
        actionInProgress: false,
        configs: [...state.configs, action.payload]
      };
    case UPDATE_CONFIG_SUCCESS:
      return {
        ...state,
        actionInProgress: false,
        configs: state.configs.map(config => 
          config.key === action.payload.key ? action.payload : config
        )
      };
    case DELETE_CONFIG_SUCCESS:
      return {
        ...state,
        actionInProgress: false,
        configs: state.configs.filter(config => config.key !== action.payload)
      };
    case CREATE_CONFIG_FAILURE:
    case UPDATE_CONFIG_FAILURE:
    case DELETE_CONFIG_FAILURE:
      return {
        ...state,
        actionInProgress: false,
        actionError: action.payload
      };
    default:
      return state;
  }
}

// Action Creators
export const fetchConfigs = () => async (dispatch) => {
  dispatch({ type: FETCH_CONFIGS_REQUEST });
  try {
    const response = await configService.getAllConfigs();
    dispatch({
      type: FETCH_CONFIGS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_CONFIGS_FAILURE,
      payload: error.message
    });
  }
};

export const createConfig = (configData) => async (dispatch) => {
  dispatch({ type: CREATE_CONFIG_REQUEST });
  try {
    const response = await configService.createConfig(configData);
    dispatch({
      type: CREATE_CONFIG_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: CREATE_CONFIG_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const updateConfig = (key, configData) => async (dispatch) => {
  dispatch({ type: UPDATE_CONFIG_REQUEST });
  try {
    const response = await configService.updateConfig(key, configData);
    dispatch({
      type: UPDATE_CONFIG_SUCCESS,
      payload: response.data
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: UPDATE_CONFIG_FAILURE,
      payload: error.message
    });
    throw error;
  }
};

export const deleteConfig = (key) => async (dispatch) => {
  dispatch({ type: DELETE_CONFIG_REQUEST });
  try {
    await configService.deleteConfig(key);
    dispatch({
      type: DELETE_CONFIG_SUCCESS,
      payload: key
    });
  } catch (error) {
    dispatch({
      type: DELETE_CONFIG_FAILURE,
      payload: error.message
    });
    throw error;
  }
}; 