import { flow, set, get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import _ from "lodash"

export const FETCH_YOUTUBE_SONGS_REQUESTED =
    "youtubeSongs/FETCH_YOUTUBE_SONGS_REQUESTED";
export const FETCH_YOUTUBE_SONGS =
    "youtubeSongs/FETCH_YOUTUBE_SONGS";
export const FETCH_YOUTUBE_SONGS_FAILED =
    "youtubeSongs/FETCH_YOUTUBE_SONGS_FAILED";
export const SET_YOUTUBE_SONGS_SEARCH_QUERY =
    "youtubeSongs/SET_YOUTUBE_SONGS_SEARCH_QUERY";
export const SEARCH_YOUTUBE_SONGS_RESULT =
    "youtubeSongs/SEARCH_YOUTUBE_SONGS_RESULT";

export const UPLOAD_YOUTUBE_SONGS_REQUESTED =
    "youtubeSongs/UPLOAD_YOUTUBE_SONGS_REQUESTED";
export const UPLOAD_YOUTUBE_SONGS_SUCCESS =
    "youtubeSongs/UPLOAD_YOUTUBE_SONGS_SUCCESS";
export const UPLOAD_YOUTUBE_SONGS_FAILED =
    "youtubeSongs/UPLOAD_YOUTUBE_SONGS_FAILED";

export const SET_SELECTED_SONG = "youtubeSongs/SET_SELECTED_SONG";
export const SET_SELECTED_URL = "youtubeSongs/SET_SELECTED_URL";
export const SET_SELECTED_NAME = "youtubeSongs/SET_SELECTED_NAME";
export const SET_SELECTED_PERIOD = "youtubeSongs/SET_SELECTED_PERIOD";
export const SET_SELECTED_TEMPO = "youtubeSongs/SET_SELECTED_TEMPO";
export const SET_SELECTED_STYLE = "youtubeSongs/SET_SELECTED_STYLE";

export const ADD_SONG = "youtubeSongs/ADD_SONG";
export const DELETE_SONG = "youtubeSongs/DELETE_SONG";

export const getDefaultSong = () => {
    return {
        ref: "",
        id: "",
        url: "",
        name: "",
        why: "",
        period: [],
        source: "youtube",
        energy: [],
        style: [],
        createdAt: null,
        thumbnailUrl: ""
    }
}

const initialState = {
    songs: [],
    isLoading: false,
    isFetchFailed: false,
    searchQuery: "",
    isLoadingSearch: false,
    filteredPlaylists: [],
    selectedSong: getDefaultSong(),
    uploading: false,
    uploadError: false
};

const deleteSongHelper = (songs, id) => {
    return songs.filter(s => s.id !== id)
}

const addSongHelper = (songs, song) => {
    let clonedSongs = _.cloneDeep(songs)
    clonedSongs.push(song)
    return clonedSongs
}

export default handleActions({
    [FETCH_YOUTUBE_SONGS_REQUESTED]: (state, action) => flow([
        set("isLoading", true),
        set("isFetchFailed", false)
    ])(state),

    [FETCH_YOUTUBE_SONGS]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", false),
        set("songs", action.payload.songs),
    ])(state),

    [FETCH_YOUTUBE_SONGS_FAILED]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", true)
    ])(state),

    [UPLOAD_YOUTUBE_SONGS_REQUESTED]: (state, action) => flow([
        set("uploading", true),
        set("uploadError", false)
    ])(state),

    [UPLOAD_YOUTUBE_SONGS_SUCCESS]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", false),
    ])(state),

    [UPLOAD_YOUTUBE_SONGS_FAILED]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", true)
    ])(state),

    [SET_YOUTUBE_SONGS_SEARCH_QUERY]: (state, action) => flow([
        set("isLoadingSearch", true),
        set("searchQuery", action.payload.query)
    ])(state),

    [SEARCH_YOUTUBE_SONGS_RESULT]: (state, action) => flow([
        set("isLoadingSearch", false),
        set("searchQuery", action.payload.filteredPlaylists)
    ])(state),

    [DELETE_SONG]: (state, action) => flow([
        set("songs", deleteSongHelper(state.songs, action.payload.id)),
    ])(state),

    [ADD_SONG]: (state, action) => flow([
        set("songs", addSongHelper(state.songs, action.payload.song)),
    ])(state),

    [SET_SELECTED_SONG]: (state, { payload }) => set("selectedSong", payload, state),

    [SET_SELECTED_URL]: (state, { payload }) => set("selectedSong.url", payload, state),

    [SET_SELECTED_NAME]: (state, { payload }) => set("selectedSong.name", payload, state),

    [SET_SELECTED_PERIOD]: (state, { payload }) => set("selectedSong.period", payload, state),

    [SET_SELECTED_TEMPO]: (state, { payload }) => set("selectedSong.tempo", payload, state),

    [SET_SELECTED_STYLE]: (state, { payload }) => set("selectedSong.style", payload, state)

}, initialState);

export const addSongToState = (song) => {
    return dispatch => {
        dispatch({
            type: ADD_SONG,
            payload: {
                song
            }
        });
    }
}

export const deleteSongFromState = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_SONG,
            payload: {
                id
            }
        });
    }
}

export const resetSelectedSong = () => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_SONG,
            payload: getDefaultSong()
        });
    }
}

export const setSelectedSong = (selected) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_SONG,
            payload: selected
        });
    }
}

export const setSongUrl = (url) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_URL,
            payload: url
        });
    }
}

export const setSongName = (name) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_NAME,
            payload: name
        });
    }
}

export const setSongPeriod = (period) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_PERIOD,
            payload: period
        });
    }
}
export const setSongTempo = (tempo) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_TEMPO,
            payload: tempo
        });
    }
}
export const setSongStyle = (style) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_STYLE,
            payload: style
        });
    }
}

export const setYoutubePlaylistsSearchQuery = (query, songs) => {
    return dispatch => {
        dispatch({
            type: SET_YOUTUBE_SONGS_SEARCH_QUERY,
            payload: { query: query }
        });

        filterSongs(query, songs).then((result) => {
            dispatch({
                type: SEARCH_YOUTUBE_SONGS_RESULT,
                payload: {
                    filteredPlaylists: result,
                }
            });
        })
    }
}

const filterSongs = async (query, songs) => {
    let filteredPlaylists = [];
    if (query) {
        filteredPlaylists = songs.filter(item => {
            const searchValue = query.toLowerCase();
            return (
                (item.name && item.name.toLowerCase().includes(searchValue)) ||
                (item.ref && item.ref === searchValue) ||
                (item.url && item.url === searchValue)
            );
        });
    } else {
        filteredPlaylists = songs;
    }
    return filteredPlaylists;
}
