import React from "react";
import { Container, CircularProgress, Grid } from "@material-ui/core";

const SplashScreen = (props, context) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item xs={3}>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default SplashScreen;
