import ColorHash from "color-hash";

export function stringToHexColor(str) {
  let colorHash = new ColorHash({
    lightness: [0.7, 0.8, 0.9]
  });
  return colorHash.hex(str);
}

export function decodeQueryDataFromUrl(url) {
  let data = {};
  let rawQueryParams = url.split("?")[1].split("&");
  rawQueryParams.forEach(rawPair => {
    let pair = rawPair.split("=");
    data[pair[0].toString()] = pair[1];
  });
  return data;
}