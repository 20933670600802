import { apiRoutes, getAuthHeader } from "./index";
import {
    FETCH_ORGANIZATIONS_REQUESTED,
    FETCH_ORGANIZATIONS_FAILED,
    FETCH_ORGANIZATIONS_SUCCESS
} from "../modules/organizations";

export const createOrganization = (organization) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.organizations, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify(organization),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const updateOrg = ({ id, name, shouldContinue, collectUsageData }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.organization(id), {
        method: 'PUT',
        // mode: 'no-cors',
        body: JSON.stringify({ name, shouldContinue, collectUsageData }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const deleteOrg = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.organization(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const deleteOrgAdmin = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.orgAdmin(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const linkSDKOrg = ({ orgId, sdkOrgId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.linkSDKOrg(orgId), {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ sdkOrgId }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}


export const addOrganizationToHouse = (organizationId, houseId) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.addOrganizationToHouse, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ organizationId, houseId }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })


}

export const createOrganizationAdmin = ({ name, email, organizationId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createOrganizationAdmin, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ name, email, organizationId }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })


}

export const list = () => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.organizations, {
        method: 'GET',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const getOrganizations = () => {
    return dispatch => {
        dispatch({
            type: FETCH_ORGANIZATIONS_REQUESTED
        });
        list()
            .then(results => {

                console.log("results", results);

                let orgs = results && results.data ? results.data : [];
                /* results.forEach(document => {
                     songs.push(document.data());
                 });*/
                console.log("ORGANIZATIONS", orgs);
                dispatch({
                    type: FETCH_ORGANIZATIONS_SUCCESS,
                    payload: {
                        orgs: orgs
                    }
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: FETCH_ORGANIZATIONS_FAILED
                });
            });
    };
};
