import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Typography, IconButton, CircularProgress, Chip, Grid, InputAdornment
} from '@material-ui/core';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Search as SearchIcon,
  Warning as WarningIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { fetchConfigs, createConfig, updateConfig, deleteConfig } from '../modules/configurations';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  searchField: {
    marginBottom: theme.spacing(3),
    width: 300,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5),
  },
  protectedChip: {
    backgroundColor: theme.palette.error.light,
    marginLeft: theme.spacing(1),
  },
  warningText: {
    color: theme.palette.warning.main,
    marginTop: theme.spacing(2),
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

const ConfigurationList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { configs, loading, error, actionInProgress } = useSelector(state => state.configurations);
  
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(null);
  const [formValues, setFormValues] = useState({
    key: '',
    value: '',
    description: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [configToDelete, setConfigToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredConfigs = configs.filter(config => 
    config.key.toLowerCase().includes(searchText.toLowerCase()) || 
    (config.description && config.description.toLowerCase().includes(searchText.toLowerCase()))
  );

  const showCreateModal = () => {
    setIsEditing(false);
    setCurrentConfig(null);
    setFormValues({
      key: '',
      value: '',
      description: ''
    });
    setFormErrors({});
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditing(true);
    setCurrentConfig(record);
    setFormValues({
      key: record.key,
      value: record.value,
      description: record.description || ''
    });
    setFormErrors({});
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.key.trim()) {
      errors.key = 'Key is required';
    } else if (!/^[a-zA-Z0-9_]+$/.test(formValues.key)) {
      errors.key = 'Key can only contain alphanumeric characters and underscores';
    }
    
    if (!formValues.value.trim()) {
      errors.value = 'Value is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    try {
      if (isEditing) {
        await dispatch(updateConfig(currentConfig.key, {
          value: formValues.value,
          description: formValues.description
        }));
        alert(`Configuration "${currentConfig.key}" updated successfully`);
      } else {
        await dispatch(createConfig({
          key: formValues.key,
          value: formValues.value,
          description: formValues.description
        }));
        alert(`Configuration "${formValues.key}" created successfully`);
      }
      
      setIsModalVisible(false);
    } catch (error) {
      console.error('Form submission error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    
    // Clear error when user types
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: undefined
      });
    }
  };

  const openDeleteConfirm = (config) => {
    setConfigToDelete(config);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteConfig(configToDelete.key));
      alert(`Configuration "${configToDelete.key}" deleted successfully`);
      setConfirmDeleteOpen(false);
      setConfigToDelete(null);
    } catch (error) {
      alert(`Failed to delete configuration: ${error.message}`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Determine if a config is system-critical (example implementation)
  const isProtectedConfig = (key) => {
    if (!key) return false;
    const protectedKeys = ['video_analysis_assistant_id', 'default_analysis_model', 'api_key'];
    return protectedKeys.includes(key);
  };

  if (error) {
    return <div className={classes.root}>Error loading configurations: {error}</div>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4">System Configurations</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={showCreateModal}
        >
          Create New Configuration
        </Button>
      </div>

      <TextField
        className={classes.searchField}
        placeholder="Search by key or description"
        variant="outlined"
        size="small"
        value={searchText}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <Paper className={classes.tableContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredConfigs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((config) => (
                    <TableRow key={config.key}>
                      <TableCell>
                        {config.key}
                        {isProtectedConfig(config.key) && (
                          <Chip 
                            size="small" 
                            label="Protected" 
                            className={classes.protectedChip}
                          />
                        )}
                      </TableCell>
                      <TableCell>{config.value}</TableCell>
                      <TableCell>{config.description}</TableCell>
                      <TableCell>
                        {config.updatedAt ? moment(config.updatedAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <IconButton 
                          size="small" 
                          color="primary" 
                          onClick={() => showEditModal(config)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          color="secondary" 
                          onClick={() => openDeleteConfirm(config)}
                          disabled={isProtectedConfig(config.key) && process.env.NODE_ENV === 'production'}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {filteredConfigs.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No configurations found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredConfigs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      {/* Create/Edit Modal */}
      <Dialog 
        open={isModalVisible} 
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? "Edit Configuration" : "Create New Configuration"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="key"
                label="Key"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formValues.key}
                onChange={handleInputChange}
                disabled={isEditing}
                error={!!formErrors.key}
                helperText={formErrors.key}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="value"
                label="Value"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formValues.value}
                onChange={handleInputChange}
                error={!!formErrors.value}
                helperText={formErrors.value}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formValues.description}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          {isEditing && currentConfig && isProtectedConfig(currentConfig.key) && (
            <Typography className={classes.warningText} variant="body2">
              <WarningIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} />
              This is a protected configuration. Changes may affect system functionality.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="default">
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            color="primary" 
            variant="contained"
            disabled={actionInProgress}
          >
            {actionInProgress ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          {configToDelete && isProtectedConfig(configToDelete.key) ? (
            <Typography>
              <WarningIcon color="error" style={{ verticalAlign: 'middle', marginRight: 8 }} />
              This is a protected configuration. Are you sure you want to delete it?
            </Typography>
          ) : (
            <Typography>
              Are you sure you want to delete this configuration?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="default">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfigurationList; 