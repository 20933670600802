import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";


import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import {
    Container,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Switch,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Divider,
    OutlinedInput,
    Select,
    Grid,
    Card,
    Chip,
    CardContent,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormGroup,
    Toolbar,
    IconButton,
    DialogContentText,
    DialogContent,
    DialogTitle,
    DialogActions,
    LinearProgress,
    InputBase
} from "@material-ui/core";

import { fade, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

function HouseForm({ onSubmit, uploading, uploadError, createdOrg, organizations, handleClose }) {

    const classes = useStyles();
    const [name, setName] = React.useState("");
    const [error, setError] = React.useState("");

    const [org, setOrg] = React.useState(null);



    const validate = () => {
        let isValid = true;
        if (!org) {
            isValid = false;
            setError("org is required")
        }

        return isValid;
    }


    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <form
                        style={{ display: "flex", flexWrap: "wrap" }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-simple">
                                Choose Organization
                            </InputLabel>
                            <Select
                                value={org}
                                onChange={event => {
                                    setOrg(event.target.value);
                                }}
                                input={
                                    <OutlinedInput
                                        name="contentLanguage"
                                        id="outlined-content-hose-type-simple"
                                    />
                                }
                            >
                                {organizations.map(org => {
                                    return (
                                        <MenuItem key={org.id} value={org.id}>
                                            {org.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                    </form>

                )}

                {error}
                {uploadError && (<div style={{ color: "red" }}>error creating org</div>)}
                {createdOrg ? (
                    <div>
                        <div>Success</div>
                    </div>
                ) : null}
            </Paper>

            {createdOrg ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    Done
                </Button>
            ) : (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        if (!validate()) {
                            return;
                        }
                        setError("")
                        onSubmit({ org })
                    }}
                >
                    Submit
                </Button>
            )}


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AttachOrganizationDialog({ open, handleClose, formProps, onSubmit, uploading, uploadError, organizations, createdOrg }) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <HouseForm onSubmit={onSubmit} createdOrg={createdOrg} uploading={uploading} uploadError={uploadError} handleClose={handleClose} organizations={organizations} />
        </Dialog>
    );
}

