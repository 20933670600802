import React, { Component, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';


import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress } from "@material-ui/core";


function PaperDropzone({onSubmit, uploading, uploadError, progress, uploadUrl}) {

    const [copySuccess, setCopySuccess] = React.useState("");

    const onDrop = useCallback((acceptedFiles) => {
        console.log("acceptedFiles", acceptedFiles)
    }, [])

    let textArea = React.createRef()
    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess( 'Copied!')
    };


    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
       // accept: 'application/vnd.ms-excel, text/csv, text/x-csv',
        onDrop,
        maxFiles: 1
    });

    const {ref, ...rootProps} = getRootProps()

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));


    return (
        <RootRef rootRef={ref} style={{padding: 50}}>
            <Paper {...rootProps} style={{padding: 50}}>
                {uploading ? (<CircularProgress/>) : (
                    <Fragment>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop file here, or click to select file</p>
                        </div>
                        <aside>
                            <h4>Accepted files</h4>
                            <ul>{acceptedFileItems}</ul>
                            <h4>Rejected files</h4>
                            <ul>{fileRejectionItems}</ul>
                        </aside>
                    </Fragment>
                )}
                {uploadError && (<div style={{color: "red"}}>error uploading file</div>)}
            </Paper>

            {uploadUrl ? (
                <div>
                    <div>Download Url:</div>
                    <textarea
                        onClick={copyToClipboard}
                        ref={(ref) => textArea = ref}
                        value={uploadUrl}
                    />
                </div>
            ) : null}

            {copySuccess}

            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={acceptedFiles.length < 1 || uploading}
                onClick={() => {
                    onSubmit(acceptedFiles[0])
                }}
            >
                Submit
            </Button>
        </RootRef>
    )

}

class MediaUpload extends Component {

    constructor() {
        super();
        this.state = {
            progress: 0,
            uploadUrl: "",
            uploading: false,
            uploadError: null
        }
    }

    uploadFile = (file) => {
        // Create the file metadata

        // Upload file and metadata to the object 'images/mountains.jpg'
        let storageRef = firebase.storage().ref();
        let uploadTask = storageRef.child('media/' + file.name).put(file);
        this.setState({uploading: true})

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }

                console.error("upload error", error)

                this.setState({
                    uploading: false,
                    uploadError: true
                })
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    this.setState({
                        uploading: false,
                        uploadUrl: downloadURL
                    })
                });
            }
        );
    }

    onSubmit = () => {}

    render() {

        const {
            progress,
            uploadUrl,
            uploading,
            uploadError
        } = this.state
        return (
            <div>
               <PaperDropzone
                    uploadError={uploadError} uploading={uploading} onSubmit={this.uploadFile} progress={progress} uploadUrl={uploadUrl}
               />
            </div>
        );
    }
}

MediaUpload.propTypes = {};

export default MediaUpload;
