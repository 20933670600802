
import { getAllTherapyContent, getAllTherapyContentAuthors } from "../api/therapyContent";

export const FETCH_THERAPY_CONTENT_REQUESTED = 'therapyContent/FETCH_THERAPY_CONTENT_REQUESTED'
export const FETCH_THERAPY_CONTENT = 'therapyContent/FETCH_THERAPY_CONTENT'
export const FETCH_THERAPY_CONTENT_FAILED = 'therapyContent/FETCH_THERAPY_CONTENT_FAILED'

const initialState = {
  items: [],
  authors: {},
  isLoadingTherapyContent: false,
  isFetchTherapyContentFailed: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THERAPY_CONTENT_REQUESTED:
      return {
        ...state,
        isLoadingTherapyContent: true,
        isFetchTherapyContentFailed: false,
      }

    case FETCH_THERAPY_CONTENT:
      return {
        ...state,
        items: action.payload.items,
        authors: action.payload.authors,
        isLoadingTherapyContent: false,
        isFetchTherapyContentFailed: false,
      }

    case FETCH_THERAPY_CONTENT_FAILED:
      return {
        ...state,
        isLoadingTherapyContent: false,
        isFetchTherapyContentFailed: true,
      }

    default:
      return state
  }
}

export const fetchTherapyContent = () => {
  return dispatch => {
    dispatch({
      type: FETCH_THERAPY_CONTENT_REQUESTED
    })
    Promise.all([getAllTherapyContentAuthors(), getAllTherapyContent()]).then(results => {
      const authorsResult = results[0];
      const itemsResult = results[1];

      let authors = {};
      authorsResult.forEach((authorSnapshot) => {
        const data = authorSnapshot.data();
        data.id = authorSnapshot.id;
        authors[data.id] = data;
      });

      let items = [];
      itemsResult.forEach((itemSnapshot) => {
        const data = itemSnapshot.data();
        data.id = itemSnapshot.id;
        data.author = authors[data.authorId];
        items.push(data);
      });

      dispatch({
        type: FETCH_THERAPY_CONTENT,
        payload: {
          items: items,
          authors: authors,
        },
      });
    }).catch(error => {
      console.log(error);
      dispatch({
        type: FETCH_THERAPY_CONTENT_FAILED
      })
    })
  }
}
