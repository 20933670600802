import React from "react";
import { Container, CircularProgress, Grid, Button, Typography } from "@material-ui/core";
import { signOut } from "../../api/firebase";

const UnauthorizedDomain = (props, context) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item xs={3}>
      <Typography>Only @imsolo.ai members are allowed to use this CMS</Typography>
      <div style={{ height: 16 }} />
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            signOut().catch(error => console.log(error));
          }}
        >
          Sign out
        </Button>
      </div>
    </Grid>
  </Grid>
);

export default UnauthorizedDomain;
