import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import moment from "moment";

import { CSVLink, CSVDownload } from "react-csv";

import {getAllSessions} from "../../api/soloSessions"

class SoloSessions extends Component {

    constructor(){
        super()
        this.state = {
            sessions: []
        }
    }

    componentDidMount = () => {
        this.fetchSessions()
    }

    fetchSessions = async () => {
        const res = await getAllSessions()
        console.log("all sessions", res)
        if(res.data){

            const baseObject = () => ({
                userId: null,
                userName: null,
                userPhone: null,
                valence: null,
                energy: null,
                neutral: null,
                happy: null,
                emotional: null,
                road: null,
                createdAt: null,
                songName: null,
                youtubeId: null,
                songEnergy: null,
                songValence: null,
            })



            let map = res.data.map(s => {

                let csvMap = [];

                const baseline = baseObject();
                baseline.userId = s.userId;
                baseline.userName = s.user.displayName;
                baseline.userPhone = s.user.phoneNumber;
                baseline.valence = s.valence;
                baseline.energy = s.energy;
                baseline.neutral = s.neutral;
                baseline.happy = s.happy;
                baseline.emotional = s.emotional;
                baseline.road = s.road;
                baseline.createdAt = s.createdAt;

                csvMap.push(baseline)

                for(let i=0; i<s.SessionLogs.length; i++) {
                    let track = s.SessionLogs[i];

                    let trackResult = baseObject();
                    trackResult.userId = s.userId;
                    trackResult.userName = s.user.displayName;
                    trackResult.userPhone = s.user.phoneNumber;
                    trackResult.valence = track.valence;
                    trackResult.energy = track.energy;
                    trackResult.neutral = track.neutral;
                    trackResult.happy = track.happy;
                    trackResult.emotional = track.emotional;
                    trackResult.road = track.road;
                    trackResult.createdAt = track.createdAt;
                    trackResult.songName = track.Song.name;
                    trackResult.youtubeId = track.Song.youtubeId;
                    trackResult.songEnergy = track.Song.energy;
                    trackResult.songValence = track.Song.valence;
                    csvMap.push(trackResult)
                }

                return csvMap;
            })


            this.setState({
                sessions: res.data,
                csvMap: map
            })
        }
    }



    render() {
        const {sessions, csvMap} = this.state

        return (
            <div>
                <h1>Sessions</h1>
                <ul>
                    {sessions.map(((s,i) => {
                        const data = csvMap[i];
                        return(
                            <li key={i} style={{display: "flex", flexDirection: "row"}}>
                                <div>{moment(s.createdAt).format('D/M/YYYY HH:mm:ss')} </div>
                                <div style={{marginLeft: 10, marginRight: 10}}>{s.user.displayName} {s.user.phoneNumber} </div>
                                <CSVLink data={data}>export to csv</CSVLink>
                            </li>
                        )
                    }))}
                </ul>

            </div>
        );
    }
}

SoloSessions.propTypes = {};

export default SoloSessions;
