import { flow, set, get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import _ from "lodash"

export const FETCH_HOUSES_REQUESTED = "houses/FETCH_HOUSES_REQUESTED";
export const FETCH_HOUSES_SUCCESS = "houses/FETCH_HOUSES_SUCCESS";
export const FETCH_HOUSES_FAILED = "houses/FETCH_HOUSES_FAILED";

export const CREATE_HOUSE_REQUESTED = "houses/CREATE_HOUSE_REQUESTED";
export const CREATE_HOUSE_SUCCESS = "houses/CREATE_HOUSE_SUCCESS";
export const CREATE_HOUSE_FAILED = "houses/CREATE_HOUSE_FAILED";

export const ADD_HOUSE = "houses/ADD_HOUSE";
export const DELETE_HOUSE = "houses/DELETE_HOUSE"

const initialState = {
    houses: [],
    isLoading: false,
    isFetchFailed: false,
    filtered: [],
    uploading: false,
    uploadError: false
};

const addHouseHelper = (houses, house) => {
    let clonedHouses = _.cloneDeep(houses)
    clonedHouses.push(house)
    return clonedHouses
}

const deleteHouseHelper = (houses, id) => {
    return houses.filter(h => h.id !== id)
}

export default handleActions({
    [FETCH_HOUSES_REQUESTED]: (state, action) => flow([
        set("isLoading", true),
        set("isFetchFailed", false)
    ])(state),

    [FETCH_HOUSES_SUCCESS]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", false),
        set("houses", action.payload.houses),
    ])(state),

    [FETCH_HOUSES_FAILED]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", true)
    ])(state),

    [CREATE_HOUSE_REQUESTED]: (state, action) => flow([
        set("uploading", true),
        set("uploadError", false)
    ])(state),

    [CREATE_HOUSE_SUCCESS]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", false),
    ])(state),

    [CREATE_HOUSE_FAILED]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", true)
    ])(state),

    [ADD_HOUSE]: (state, action) => flow([
        set("houses", addHouseHelper(state.houses, action.payload.house)),
    ])(state),

    [DELETE_HOUSE]: (state, action) => flow([
        set("houses", deleteHouseHelper(state.houses, action.payload.id)),
    ])(state),

}, initialState);

export const addHouseToState = (house) => {
    return dispatch => {
        dispatch({
            type: ADD_HOUSE,
            payload: {
                house
            }
        });
    }
}

export const deleteHouseFromState = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_HOUSE,
            payload: {
                id
            }
        });
    }
}