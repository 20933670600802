//import { firestore } from "./firebase";
import { FIRESTORE_COLLECTION_NAMES } from "./constants";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { firestore } from "./firebase";



export const PERIODS = ["Nostalgic", "Modern"];
export const PERIODS_YEARS = ["1900", "1910","1920","1930","1940","1950","1960","1970","1980","1990","2000"];
export const ENERGIES = ["Low", "Mixed", "High"];

export async function getAllTherapyContent() {
  return (await firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
    .get()).docs;
}

export async function getAllTherapyContentAuthors() {
  return (await firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContentAuthors)
    .get()).docs;
}

export async function setTherapyContentItemPublished(itemId, published) {
  return firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
    .doc(itemId)
    .update({
      published: published
    });
}

export async function removeContentItemTag(itemId, tagToRemove) {
  return firestore.runTransaction(async transaction => {
    const itemRef = firestore
      .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
      .doc(itemId);
    let item = (await transaction.get(itemRef)).data();
    let updatedTags = item.tags;
    updatedTags = updatedTags.filter(tag => {
      return tag !== tagToRemove;
    });
    item.tags = updatedTags;
    transaction.update(itemRef, { tags: updatedTags });
    return updatedTags;
  });
}

export async function addContentItemTag(itemId, tagToAdd) {
  return firestore.runTransaction(async transaction => {
    if (tagToAdd) {
      const itemRef = firestore
        .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
        .doc(itemId);
      let item = (await transaction.get(itemRef)).data();
      let updatedTags = item.tags;
      if (!updatedTags.includes(tagToAdd)) {
        updatedTags.push(tagToAdd);
        item.tags = updatedTags;
        transaction.update(itemRef, { tags: updatedTags });
        return updatedTags;
      } else {
        throw new Error("tag_already_exists");
      }
    } else {
      throw new Error("empty_tag");
    }
  });
}

export async function setContentItemContentLanguage(itemId, language) {
  return firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
    .doc(itemId)
    .update({ contentLanguage: language });
}

export async function setContentItemAuthorId(itemId, authorId) {
  return firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
    .doc(itemId)
    .update({ authorId: authorId });
}

export async function updateContentItemTargetLanguages(
  itemId,
  languageToUpdate,
  isSupported
) {
  return firestore.runTransaction(async transaction => {
    if (languageToUpdate) {
      const itemRef = firestore
        .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
        .doc(itemId);
      let item = (await transaction.get(itemRef)).data();
      let updatedLanguages = item.targetLanguages;
      updatedLanguages[languageToUpdate] = isSupported;
      transaction.update(itemRef, { targetLanguages: updatedLanguages });
      return updatedLanguages;
    } else {
      throw new Error("empty_tag");
    }
  });
}

export async function updateTherapyContentItemTitle(itemId, title) {
  return firestore
    .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
    .doc(itemId)
    .update({ title: title });
}

export async function removeTherapyContentFile(itemId, fileId) {
  if (!itemId || !fileId) {
    throw new Error("empty itemId or fileId");
  }
  await firebase.storage().ref(`therapy-content/videos/${fileId}`).delete();
  const newItemData = {
    contentId: "",
    contentUrl: "",
    contentUploadedAt: 0,
  };
  await firebase.firestore().collection(FIRESTORE_COLLECTION_NAMES.therapyContent).doc(itemId).update(newItemData);
  return newItemData;
}

export async function uploadTherapyContentFile(itemId, file, fileName, onProgressCallback, onSuccessCallback, onErrorCallback) {
  console.log(itemId, file, fileName);

  const fileNameArgs = fileName.split(".");
  const suffix = fileNameArgs[fileNameArgs.length - 1];
  const uploadedAt = Date.now().toString();
  const uploadedFileName = `${itemId}_${uploadedAt}.${suffix}`;
  const refPath = `therapy-content/videos/${uploadedFileName}`;
  console.log("onload", refPath);
  let uploadTask = firebase
    .storage()
    .ref(refPath)
    .put(file);
  uploadTask.on(
    "state_changed",
    function(snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      if (onProgressCallback) {
        onProgressCallback(parseInt(progress, 10));
      }
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    function(error) {
      // Handle unsuccessful uploads
      console.log("uploadTherapyContentFile", error);
      deleteTherapyContentItemData(itemId).then(() => {
        console.log("item deleted", itemId);
      }).catch((error) => console.log("failed to delete item", error));
      onErrorCallback();
    },
    function() {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log("File available at", downloadURL);
        const updatedItemData = {
          contentId: uploadedFileName,
          contentUrl: downloadURL,
          contentUploadedAt: uploadedAt,
        };
        firebase
          .firestore()
          .collection(FIRESTORE_COLLECTION_NAMES.therapyContent)
          .doc(itemId)
          .update(updatedItemData);
        onSuccessCallback(updatedItemData);
      });
    }
  );

  // const reader = new FileReader();

  // reader.onabort = () => console.log("file reading was aborted");
  // reader.onerror = () => console.log("file reading has failed");
  // reader.onload = () => {

  // };

  // reader.readAsArrayBuffer(file);
}

export async function createTherapyContentItem(title, contentLanguage, targetLanguages, tags, mediaType, authorId) {
  let data = {
    title: title,
    contentLanguage: contentLanguage,
    targetLanguages: targetLanguages,
    tags: tags,
    mediaType: mediaType,
    published: false,
    likes: 0,
    authorId: authorId,
  };
  let newDocRef = firebase.firestore().collection(FIRESTORE_COLLECTION_NAMES.therapyContent).doc();
  data.ref = newDocRef.id;
  await newDocRef.set(data);
  return data;
}

export async function updateTherapyContentItem(itemId, data) {
  return await firebase.firestore().collection(FIRESTORE_COLLECTION_NAMES.therapyContent).doc(itemId).update(data);
}

export async function deleteTherapyContentItemData(itemId) {
  if (itemId) {
    return await firebase.firestore().collection(FIRESTORE_COLLECTION_NAMES.therapyContent).doc(itemId).delete();
  } else {
    throw new Error("empty item id")
  }
}

export async function removeTherapyContentItem(itemId, fileId) {
  if (fileId) {
    await removeTherapyContentFile(itemId, fileId);
  }
  await deleteTherapyContentItemData(itemId);
}
