import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';


import { Button, CircularProgress, List } from "@material-ui/core";
import CreateOrganizationDialog from "../CreateOrganizationDialog";
import CreateOrganizationAdminDialog from "./CreateOrganizationAdminDialog"
import { useStyles } from "../common/styles";

import { addOrgToState, deleteOrgFromState } from "../../modules/organizations";

import {
    createOrganization,
    createOrganizationAdmin,
    getOrganizations,
    updateOrg,
    deleteOrgAdmin,
    deleteOrg,
    addOrganizationToHouse, linkSDKOrg
} from "../../api/organization"
import { getOrganizations as getSDKOrganizations } from "../../api/sdkOrganization"
import Table, { NumberRangeColumnFilter } from "../Table/Table";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";

import Checkbox from "@material-ui/core/Checkbox";

import EditableField from "../EditableField";
import { OrgName } from "../CreateOrganizationDialog";
import { HouseType } from "../Houses/CreateHouseDialog";
import CreateAdminsEditDialog from "../Houses/AdminsEditDialog";
import { deleteHouse, deleteHouseAdmin } from "../../api/house";
import { ConfirmationDialogRaw } from "../ConfirmDelete";
import LinkSDKOrgDialog from "./LinkSDKOrgDialog";
import CloseIcon from "@material-ui/icons/Close";

const adminAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => item.email) : [];
    let join = map.join();
    return join
}


function Content({ addNewItemMode, setAddNewItemMode, isLoading, isFetchFailed, organizations, createAdmin, removeLocalOrgAdmin, onRemoveItem, openLinkOrganization }) {

    const classes = useStyles();

    const [admins, setAdmins] = React.useState([]);
    const [adminsDialogOpen, setAdminsDialogOpen] = React.useState(false);
    const [adminsRes, setAdminsRes] = React.useState(null);
    const [savingAdmins, setAsSvingAdmins] = React.useState(false);
    const [savingAdminsError, setAsSvingAdminsError] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [selected, setSelected] = React.useState(null);
    const [data, setData] = React.useState(organizations);

    const handleClose = async (shouldDelete = false) => {
        setOpen(false);

        if (shouldDelete) {
            let res = await onRemoveItem(selected);
            console.log("delete", res)
        }
        setSelected(null)
        setMessage("")
    };

    const resetAdminsDialog = () => {
        setAdmins([])
        setAdminsDialogOpen(false)
        setAdminsRes(null)
        setAsSvingAdmins(false)
        setAsSvingAdminsError(null)
    }

    const onDeleteAdmin = async (id) => {
        try {
            setAsSvingAdmins(true)
            await deleteOrgAdmin({ id })
            setAsSvingAdmins(false)
        } catch (e) {
            setAsSvingAdmins(false)
            setAsSvingAdminsError(e)
        }

    }

    const updateField = async ({ id, key, value }) => {
        console.log("updating org", { id, key, value })
        let payload = { id }
        payload[key] = value
        let res = await updateOrg(payload)
        console.log("res", res)
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                filter: 'fuzzyText',
                Cell: ({ row, value }) => {
                    return <EditableField value={value} renderInput={OrgName} onSave={(value) => {
                        updateField({ id: row.original.id, key: "name", value })
                    }} />
                }
            },
            {
                Header: 'Admins',
                id: "Admins",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => adminAccessor(originalRow, rowIndex, "OrganizationAdmins"),
                Cell: ({ row, value }) => {
                    return (
                        <EditableField
                            value={value}
                            onEdit={() => {
                                console.log("on edit")
                                setAdminsDialogOpen(true)
                                setAdmins(row.original.OrganizationAdmins)

                            }}
                            onSave={async (id) => {
                                console.log("save", id)

                            }}
                            renderInput={
                                ({ value, setValue, onSave }) => {
                                    return <CreateAdminsEditDialog
                                        result={adminsRes}
                                        admins={admins}
                                        handleClose={resetAdminsDialog}
                                        onSubmit={async (id) => {
                                            await onDeleteAdmin(id)
                                            onSave()
                                            removeLocalOrgAdmin(row.original.id, id)
                                        }}
                                        uploading={savingAdmins}
                                        uploadError={savingAdminsError}
                                        open={adminsDialogOpen}
                                    />
                                }
                            }
                        />
                    )
                }
            },
            {
                id: "actions",
                Header: "",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id }),
                filter: null,
                Filter: () => null,
                Cell: ({ value }) => (
                    <Button
                        onClick={() => {
                            //house id - value.id
                            console.log("create admin clicked", value.id)
                            createAdmin(value.id)
                        }}
                    >
                        {"Add Admin"}
                    </Button>
                )
            },
            {
                id: "linkedSDKOrg",
                Header: "SDK ORG",
                accessor: (originalRow, rowIndex) => originalRow.sdkOrg || "",
                filter: null,
                Filter: () => null,
                Cell: ({ value , row}) => {
                    if (value) {
                        return (
                            <div>
                                {value}
                                <CloseIcon style={{ cursor: "pointer" }} onClick={() => {
                                  //  onDeleteOrganization(row.original.id, value, row.original.name)

                                }} />
                            </div>
                        )
                    } else {
                        return (
                            <Button
                                onClick={() => {
                                    //house id - value.id
                                    console.log("link org clicked", row.original.id)
                                    openLinkOrganization(row.original.id)
                                }}
                            >
                                {"Link SDK ORG"}
                            </Button>
                        )
                    }
                }
            },
            {
                id: "actions2",
                Header: "",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id, name: originalRow.name }),
                filter: null,
                Filter: () => null,
                Cell: ({ value }) => (
                    <IconButton color="primary" aria-label="delete" component="span" onClick={() => {
                        setMessage(`Are you sure you want to delete "${value.name}"?`)
                        setOpen(true)
                        setSelected(value.id)
                    }}>
                        <Delete />
                    </IconButton>
                )
            },
            {
                id: "continue",
                Header: "Continue App",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id, shouldContinue: originalRow.shouldContinue }),
                filter: null,
                Filter: () => null,
                Cell: ({ row, value }) => {
                    return (<Checkbox
                        checked={value.shouldContinue}
                        color="primary"
                        onChange={() => {
                            updateContinueInServer(value.id, !value.shouldContinue)
                            updateMyData(row.index, "shouldContinue", !value.shouldContinue)
                        }}
                    />)
                }
            }
        ],
        [admins,
            adminsDialogOpen,
            adminsRes,
            savingAdmins,
            savingAdminsError]
    )

    const updateContinueInServer = async (id, shouldContinue) => {
        await updateOrg({ id, shouldContinue })
    }

    const updateUsageDataInServer = async (id, collectUsageData) => {
        await updateOrg({ id, collectUsageData })
    }

    const updateMyData = (rowIndex, columnId, value) => {
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    return (
        <div className={classes.root}>

            <Button
                variant="contained"
                component="span"
                color={addNewItemMode ? "secondary" : "primary"}
                onClick={() => {
                    setAddNewItemMode(!addNewItemMode)
                }}
            >
                {addNewItemMode ? "Cancel" : "Create Organization"}
            </Button>
            {addNewItemMode ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    style={{ marginLeft: 16 }}
                >
                    Save
                </Button>
            ) : null}

            {isLoading ? (
                <CircularProgress />
            ) : isFetchFailed ? (
                "Error"
            ) : (
                <Table
                    columns={columns}
                    data={data}
                />
            )}

            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                message={message}
                title={"Confirm Delete"}
            />
        </div>
    );
}

class Organizations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewItemMode: false,
            addAdminMode: false,

            uploading: false,
            uploadError: false,
            orgHouseId: null,
            createdAdmin: null,
            createdOrg: null,
            linkedOrg: null,
        };
    }

    componentDidMount() {
        this.props.getOrganizations()
    }

    closeCreateAdminDialog = () => {
        this.setState({
            addAdminMode: false,
            orgHouseId: null,
            createdAdmin: null
        })
    }

    submit = async (org) => {
        console.log("saving organization", org)
        try {
            this.setState({ uploading: true })
            let res = await createOrganization(org)
            console.log("res", res);
            this.setState({ createdOrg: res })
            this.setState({ uploading: false })
            //this.props.getOrganizations()
            this.props.addOrgToState(res.organization)
        } catch (e) {
            console.error("error creating organization", e)
            alert("error creating organization")
            this.props.deleteOrgFromState(undefined)
            this.setState({ uploading: false, uploadError: true })
        }
    }

    submitAdmin = async ({ name, email }) => {
        try {
            this.setState({ uploading: true })
            const { orgHouseId } = this.state;
            let res = await createOrganizationAdmin({ name, email, organizationId: orgHouseId })

            if (res && res.errors) {
                this.setState({ uploading: false, uploadError: true })
                return
            }

            this.setState({ uploading: false, createdAdmin: res })
            this.props.getOrganizations()
        } catch (e) {
            console.error("error creating house admin", e)
            this.setState({ uploading: false, uploadError: true })
        }


    }

    linkOrganization = async (sdkOrgId) => {
        const { srcOrgId } = this.state
        console.log("linking org", {srcOrgId, sdkOrgId})
        try {
            this.setState({ uploading: true })
            let res = await linkSDKOrg({ orgId: srcOrgId, sdkOrgId })
            console.log("res", res);
            this.setState({ uploading: false, linkedOrg: res })
            this.props.getOrganizations()
           // this.props.fetchHouses()
        } catch (e) {
            console.error("error creating org", e)
            this.setState({ uploading: false, uploadError: true })
        }
    }

    onRemove = async (orgId) => {
        const { organizations } = this.props;
        const orgIndex = _.findIndex(organizations, { id: orgId })
        const org = organizations[orgIndex]
        try {
            let res = await deleteOrg({ id: orgId })
            //this.props.getOrganizations()
            this.props.deleteOrgFromState(orgId)
        } catch (e) {
            console.error("onRemove error", e)
            alert("Error Removing organization")
            this.props.addOrgToState(org)
        }
    }

    render() {
        const { uploading, uploadError, createdAdmin, createdOrg } = this.state;
        const { organizations, isLoading, isFetchFailed } = this.props;

        console.log("isFetchFailed", isFetchFailed)

        return (
            <div style={{ flex: 1, height: "100%", width: "100%" }}>

                <Content
                    organizations={organizations}
                    isFetchFailed={isFetchFailed}
                    isLoading={isLoading}
                    addNewItemMode={this.state.addNewItemMode}
                    setAddNewItemMode={(addNewItemMode) => { this.setState({ addNewItemMode }) }}
                    createAdmin={(orgHouseId) => { this.setState({ addAdminMode: true, orgHouseId }) }}
                    removeLocalOrgAdmin={(orgId, adminId) => {
                        let index = _.findIndex(organizations, { id: orgId })
                        if (index === -1) {
                            return
                        }
                        _.remove(organizations[index].OrganizationAdmins, (a) => {
                            return a.id === adminId
                        })

                        this.setState({ organizations })

                    }}
                    onRemoveItem={this.onRemove}
                    openLinkOrganization={(orgId) => {
                        this.setState({ linkSDKOrgMode: true, srcOrgId: orgId })
                    }}
                />
                <CreateOrganizationDialog
                    open={this.state.addNewItemMode}
                    handleClose={() => {
                        this.setState({
                            addNewItemMode: false,
                            createdOrg: null
                        });
                    }}
                    formProps={this.props}
                    onSubmit={this.submit}
                    uploading={uploading}
                    uploadError={uploadError}
                    createdOrg={createdOrg}
                    hasContinueFlag={true}
                />

                <CreateOrganizationAdminDialog
                    handleClose={this.closeCreateAdminDialog}
                    onSubmit={this.submitAdmin}
                    createdAdmin={createdAdmin}
                    uploading={uploading}
                    uploadError={uploadError}
                    open={this.state.addAdminMode}
                />

                <LinkSDKOrgDialog
                    organizations={this.props.sdkOrganizations}
                    open={this.state.linkSDKOrgMode}
                    handleClose={() => {
                        this.setState({
                            linkSDKOrgMode: false,
                            linkedOrg: null
                        });
                    }}

                    formProps={this.props}
                    onSubmit={this.linkOrganization}
                    uploading={uploading}
                    uploadError={uploadError}
                    createdOrg={this.state.linkedOrg}
                />
            </div>
        );
    }
}

Organizations.propTypes = {};

const mapStateToProps = ({ organizations, sdkOrganizations }) => ({
    organizations: organizations.organizations,
    filtered: organizations.filtered,
    isLoading: organizations.isLoading || organizations.isLoadingSearch || sdkOrganizations.isLoading || sdkOrganizations.isLoadingSearch,
    isFetchFailed: organizations.isFetchFailed,
    sdkOrganizations: sdkOrganizations.organizations,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOrganizations,
            addOrgToState,
            deleteOrgFromState,
            getSDKOrganizations
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);


