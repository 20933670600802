import { apiRoutes, getAuthHeader } from "./index";
import {
    FETCH_SDK_ORGANIZATIONS_REQUESTED,
    FETCH_SDK_ORGANIZATIONS_SUCCESS,
    FETCH_SDK_ORGANIZATIONS_FAILED
} from "../modules/sdkOrganizations";

export const createOrganization = (organization) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.sdkOrganizations, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify(organization),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const updateOrg = ({ id, name, collectUsageData, appLevelUnitsLimitEnabled }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.sdkOrganization(id), {
        method: 'PUT',
        // mode: 'no-cors',
        body: JSON.stringify({ name, collectUsageData, appLevelUnitsLimitEnabled }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const deleteOrg = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    return fetch(apiRoutes.sdkOrganization(id), {
        method: 'DELETE',
        headers: headers,
    })
        .then(response => {
            return response.json()
        })
}

export const deleteOrgAdmin = (id) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    return fetch(apiRoutes.sdkOrgAdmin(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}


export const createOrganizationAdmin = ({ name, email, organizationId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createSdkOrganizationAdmin, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ name, email, OrganizationId: organizationId }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const createOrganizationPricingTier = ({ minUnits, discount, organizationId, currency, unitType }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createSdkOrgPricingTier, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ minUnits, discount, organizationId: organizationId, currency, unitType }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const createOrgUnitsLimit = ({ OrgId, unitsLimit, startDate, endDate }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createSdkOrgUnitsLimit, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ OrgId, OrgClientAppId: null, unitsLimit, startDate, endDate }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}


export const list = () => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.sdkOrganizations, {
        method: 'GET',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const getOrganizations = () => {
    return dispatch => {
        dispatch({
            type: FETCH_SDK_ORGANIZATIONS_REQUESTED
        });
        list()
            .then(results => {
                console.log("results", results);
                let orgs = results ? results : [];
                console.log("SDK-ORGANIZATIONS", orgs);
                dispatch({
                    type: FETCH_SDK_ORGANIZATIONS_SUCCESS,
                    payload: {
                        orgs: orgs
                    }
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: FETCH_SDK_ORGANIZATIONS_FAILED
                });
            });
    };
};
