import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";

import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import {
    Container,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Switch,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Divider,
    OutlinedInput,
    Select,
    Grid,
    Card,
    Chip,
    CardContent,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormGroup,
    Toolbar,
    IconButton,
    DialogContentText,
    DialogContent,
    DialogTitle,
    DialogActions,
    LinearProgress,
    InputBase
} from "@material-ui/core";
import { LANGUAGES_MAP } from "../../api/constants";
import { fade, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function AdminForm({ onSubmit, uploading, uploadError, createdAdmin, handleClose }) {

    const classes = useStyles();
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState("");
    const [copySuccess, setCopySuccess] = React.useState("");

    let textArea = React.createRef()

    //  const [customHouseKey, setCustomHouseKey] = React.useState();

    const validate = () => {
        let isValid = true;
        if (!name) {
            isValid = false;
            setError("name is required")
        } else if (!email) {
            isValid = false;
            setError("email is required")
        } else {
            let validEmal = validateEmail(email);
            console.log("is valid email", validEmal)
            if (!validEmal) {
                isValid = false;
                setError("invalid email address")
            }
        }

        return isValid;
    }

    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!')
    };

    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <form
                        style={{ display: "flex", flexWrap: "wrap" }}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField
                            id="outlined-dense"
                            label="Name"
                            margin="dense"
                            variant="outlined"
                            value={name}
                            onChange={event => {
                                setName(event.target.value);
                            }}
                        />

                        <TextField
                            id="outlined-dense"
                            label="Email"
                            margin="dense"
                            variant="outlined"
                            value={email}
                            onChange={event => {
                                setEmail(event.target.value);
                            }}
                        />
                    </form>

                )}

                {error}
                {uploadError && (<div style={{ color: "red" }}>error creating admin</div>)}
                {createdAdmin ? createdAdmin.newUser ? (
                    <div>
                        <div>Created Admin Password:</div>
                        <textarea
                            onClick={copyToClipboard}
                            ref={(ref) => textArea = ref}
                            value={createdAdmin.password || null}
                        />
                    </div>
                ) : <div>Success - Admin created, user already exist in the system</div> : null}

                {copySuccess}
            </Paper>

            {createdAdmin ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        handleClose()
                    }}
                >
                    Done
                </Button>
            ) : (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    disabled={uploading}
                    onClick={() => {
                        if (!validate()) {
                            return;
                        }
                        setError("")
                        onSubmit({ name, email })
                    }}
                >
                    Submit
                </Button>)}


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateHouseAdminDialog({
    open,
    handleClose,
    formProps,
    onSubmit,
    uploading,
    uploadError,
    createdAdmin
}) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <AdminForm
                onSubmit={onSubmit}
                uploading={uploading}
                uploadError={uploadError}
                handleClose={handleClose}
                createdAdmin={createdAdmin}
            />
        </Dialog>
    );
}

