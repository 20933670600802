import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { checkIfEmailAllowToSignInWithGoogle, signInWithGoogle } from '../../api/firebase';
import { DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        2gether Music LTD
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const [state, setState] = useState(
    {
        email: "",
        showNotAllowedAlert: false,
    }
  );

  const _setState = newState => setState(Object.assign({}, state, newState));

  const onEmailInputChanged = event => _setState({email: event.target.value});

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Dialog
        open={state.showNotAllowedAlert}
        onClose={() => _setState({showNotAllowedAlert: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Invalid domain</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Only imsolo.ai accounts that signed-in with Google are allowed to login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => _setState({showNotAllowedAlert: false})} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          2gether CMS
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={onEmailInputChanged}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
                if (state.email.endsWith("@2gether.fun") || state.email.endsWith("@imsolo.ai")) {
                    signInWithGoogle().catch(error => console.log(error));
                } else {
                    _setState({ showNotAllowedAlert: true });
                }
            }}
          >
            Sign In
          </Button>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
