import { fetchAllYoutubePlaylists } from "../api/youtubePlaylists";

export const FETCH_YOUTUBE_PLAYLISTS_REQUESTED =
  "youtubePlaylists/FETCH_YOUTUBE_PLAYLISTS_REQUESTED";
export const FETCH_YOUTUBE_PLAYLISTS =
  "youtubePlaylists/FETCH_YOUTUBE_PLAYLISTS";
export const FETCH_YOUTUBE_PLAYLISTS_FAILED =
  "youtubePlaylists/FETCH_YOUTUBE_PLAYLISTS_FAILED";
export const SET_YOUTUBE_PLAYLISTS_SEARCH_QUERY =
  "youtubePlaylists/SET_YOUTUBE_PLAYLISTS_SEARCH_QUERY";
export const SEARCH_YOUTUBE_PLAYLISTS_RESULT =
  "youtubePlaylists/SEARCH_YOUTUBE_PLAYLISTS_RESULT";

const initialState = {
  playlists: [],
  isLoadingYoutubePlaylists: false,
  isFetchYoutubePlaylistsFailed: false,
  searchQuery: "",
  isLoadingSearch: false,
  filteredPlaylists: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_YOUTUBE_PLAYLISTS_REQUESTED:
      return {
        ...state,
        isLoadingYoutubePlaylists: true,
        isFetchYoutubePlaylistsFailed: false
      };

    case FETCH_YOUTUBE_PLAYLISTS:
      return {
        ...state,
        playlists: action.payload.playlists,
        isLoadingYoutubePlaylists: false,
        isFetchYoutubePlaylistsFailed: false
      };

    case FETCH_YOUTUBE_PLAYLISTS_FAILED:
      return {
        ...state,
        isLoadingYoutubePlaylists: false,
        isFetchYoutubePlaylistsFailed: true
      };

    case SET_YOUTUBE_PLAYLISTS_SEARCH_QUERY:
      return {
        ...state,
        isLoadingSearch: true,
        searchQuery: action.payload.query,
      };

    case SEARCH_YOUTUBE_PLAYLISTS_RESULT:
      return {
        ...state,
        isLoadingSearch: false,
        filteredPlaylists: action.payload.filteredPlaylists
      };
    default:
      return state;
  }
};

export const fetchYoutubePlaylists = () => {
  return dispatch => {
    dispatch({
      type: FETCH_YOUTUBE_PLAYLISTS_REQUESTED
    });

    fetchAllYoutubePlaylists()
      .then(results => {
        let playlists = [];
        results.forEach(document => {
          playlists.push(document.data());
        });
        console.log("PLAYLISTS", playlists);
        dispatch({
          type: FETCH_YOUTUBE_PLAYLISTS,
          payload: {
            playlists: playlists
          }
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: FETCH_YOUTUBE_PLAYLISTS_FAILED
        });
      });
  };
};

export const setYoutubePlaylistsSearchQuery = (query, playlists) => {
  return dispatch => {
    dispatch({
      type: SET_YOUTUBE_PLAYLISTS_SEARCH_QUERY,
      payload: { query: query }
    });

    filterPlaylists(query, playlists).then((result) => {
      dispatch({
        type: SEARCH_YOUTUBE_PLAYLISTS_RESULT,
        payload: {
          filteredPlaylists: result,
        }
      });
    })
  }
}

const filterPlaylists = async (query, playlists) => {
  let filteredPlaylists = [];
  if (query) {
    filteredPlaylists = playlists.filter(item => {
      const searchValue = query.toLowerCase();
      return (
        (item.name && item.name.toLowerCase().includes(searchValue)) ||
        (item.ref && item.ref === searchValue) ||
        (item.url && item.url === searchValue)
      );
    });
  } else {
    filteredPlaylists = playlists;
  }
  return filteredPlaylists;
}