import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Home from "../home/index";
import SplashScreen from "../splash/index";
import AppBar from "../../components/appbar/index";
import Login from "../login/login";
import { setAuthStateChangedListener, signOut } from "../../api/firebase";
import UnauthorizedDomain from "../login/unauthorizedDomain";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      authorizedUser: false,
    };
  }

  componentDidMount() {
    setAuthStateChangedListener((user) => {
      if (user) {
        if (user.email.endsWith("@2gether.fun") || user.email.endsWith("@imsolo.ai")) {
            user.getIdToken().then((idToken) =>{  // <------ Check this line
                console.log("user token", idToken); // It shows the Firebase token now
                localStorage.setItem("userToken", idToken)
                this.setState({
                    authorizedUser: true
                }, () => {
                    this.props.history.push("/home");
                });
            });
        } else {
          // signOut().catch(error => console.log(error));
          this.props.history.push("/unauthorized-domain");
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    return (
      <div>
        <header>
          {/* {this.props.location.pathname !== "/login" && this.props.location.pathname !== "/" ? <AppBar /> : ""} */}
        </header>

        <main>
          <Route exact path="/" component={SplashScreen} />
          <Route exact path="/unauthorized-domain" component={UnauthorizedDomain} />
          <Route exact path="/home" component={this.state.authorizedUser ? Home : SplashScreen} />
          <Route exact path="/login" component={Login} />
        </main>
      </div>
    );
  }
}

export default withRouter(App);
