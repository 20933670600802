import React, { Component, useCallback } from "react";
import PropTypes from "prop-types";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchTherapyContent } from "../../modules/therapyContent";
import AddIcon from '@material-ui/icons/Add';
import VideocamIcon from '@material-ui/icons/Videocam';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import {
  Container,
  CircularProgress,
  Paper,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Button,
  Switch,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Divider,
  OutlinedInput,
  Select,
  Grid,
  Card,
  Chip,
  CardContent,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  Dialog,
  Toolbar,
  IconButton,
  Slide,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
  InputBase
} from "@material-ui/core";
import { getTherapyContentAuthorImageUrlByImageId } from "../../api/helpers";
import { setTherapyContentItemPublished, removeContentItemTag, addContentItemTag, setContentItemContentLanguage, updateContentItemTargetLanguages, updateTherapyContentItemTitle, uploadTherapyContentFile, createTherapyContentItem, updateTherapyContentItem, setContentItemAuthorId, removeTherapyContentFile, removeTherapyContentItem } from "../../api/therapyContent";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactPlayer from "react-player";
import { LANGUAGES_MAP } from "../../api/constants";
import Dropzone, { useDropzone } from "react-dropzone";
import { stringToHexColor } from "../../utils";

const copy = require('clipboard-copy');

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent"
  },
  list: {
    width: "100%",
    backgroundColor: "transparent"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  chip: {
    margin: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.secondary.main, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
}));

function Item(data) {
  const classes = useStyles();

  const [item, setItem] = React.useState(data.item);

  const [titleInputValue, setTitleInputValue] = React.useState(data.item.title);

  const [newTagInputValue, setNewTagInputValue] = React.useState("");

  const [isLoadingPublishedChange, setIsLoadingPublishedChange] = React.useState(false);

  const _setItem = newItem => setItem(Object.assign({}, item, newItem));

  const allowEdit = item.published === false;
  
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [alertDialogState, setAlertDialogState] = React.useState({
    open: false,
  });

  const [localFile, setLocalFile] = React.useState(null);
  const [localFileName, setLocalFileName] = React.useState("");
  const [localFileSize, setLocalFileSize] = React.useState(0);
  const [fileUploadState, setFileUploadedState] = React.useState(null);

  const [isRemovingFile, setIsRemovingFile] = React.useState(false);

  const [isRemovingItem, setIsRemovingItem] = React.useState(false);

  const [expanded, setExpanded] = React.useState(false);

  function isAllowToPublish() {
    return !isRemovingFile && !isRemovingItem && item.title && item.tags && item.contentLanguage && item.targetLanguages && item.authorId && item.contentUrl && item.contentId && item.mediaType;
  }

  function handleRemoveFileClick() {
    setIsRemovingFile(true);
    removeTherapyContentFile(item.ref, item.contentId).then((updatedItemValues) => {
      _setItem(updatedItemValues);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setIsRemovingFile(false);
    });
  }

  function handleUploadNewFileClick() {
    uploadTherapyContentFile(item.ref, localFile, localFileName, (progress) => {
      console.log(progress);
      setFileUploadedState(Object.assign({}, {progress: progress, uploadFinished: false}));
    }, (updatedItemData) => {
      console.log("upload finished - success callback");
      setFileUploadedState(Object.assign({}, { uploadFinished: true }));
      _setItem(updatedItemData);
    }, () => {
      console.log("upload error - callback");
      setFileUploadedState(null);
    }).then(() => {
      console.log("upload finished - then");
    }).catch((error) => {
      console.log("upload error - catch", error);
      setFileUploadedState(null);
    });
  }

  function handleDeleteItemForeverClick() {
    setIsRemovingItem(true);
    setAlertDialogState({
      open: true,
      title: "Deleting content item",
      message: "Please wait..."
    });
    removeTherapyContentItem(item.ref, item.contentId)
      .then(() => {
        data.props.fetchTherapyContent();
      })
      .catch(error => console.log(error))
      .finally(() => {
        setIsRemovingItem(false);
        setAlertDialogState({
          open: false,
        })
      });
  }

  let author = data.props.therapyContentAuthors[item.authorId];

  function getItemDeepLinkURI() {
    return `com.2gether.fun://link/therapyContent/${item.mediaType}/${item.ref}`;
  }

  return (
    <Paper style={{ marginBottom: 8, width: "100%" }} key={item.title}>
      <ExpansionPanel style={{ width: "100%" }} expanded={expanded}>
        <ExpansionPanelSummary
          style={{ width: "100%" }}
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
                console.log("expanded");
                setExpanded(!expanded);
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#f4f4f4",
                borderRadius: 4,
                padding: 8
              }}
            >
              <VideocamIcon color="primary" />
            </div>
            <div style={{ width: 16 }} />
            <Avatar
              src={
                author
                  ? getTherapyContentAuthorImageUrlByImageId(
                      data.props.therapyContentAuthors[item.authorId].imageId
                    )
                  : null
              }
            />
            <div style={{ width: 16 }} />
            <ListItemText
              primary={item.title}
              secondary={author ? author.defaultName : ""}
              onClick={() => {
                console.log("expanded");
                setExpanded(!expanded);
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#f4f4f4",
                borderRadius: 4,
                padding: 8,
                marginRight: 8
              }}
            >
              <Typography>{item.likes}</Typography>
              <FavoriteIcon style={{ marginLeft: 8 }} color={"secondary"} />
            </div>
            <Switch
              color={"primary"}
              disabled={isLoadingPublishedChange}
              checked={item.published}
              onClick={() => {
                console.log("switch");
              }}
              onChange={event => {
                if (isAllowToPublish()) {
                  setIsLoadingPublishedChange(true);
                  const newValue = event.target.checked;
                  setTherapyContentItemPublished(item.id, newValue)
                    .then(() => {
                      _setItem({ published: newValue });
                      setExpanded(!newValue);
                    })
                    .catch(error => {
                      console.log(error);
                      _setItem({ published: !newValue });
                    })
                    .finally(() => {
                      setIsLoadingPublishedChange(false);
                    });
                } else {
                  setAlertDialogState({
                    open: true,
                    title: "Missing or invalid details",
                    message: "Please make sure your input is complete and valid"
                  });
                }
              }}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ flexDirection: "column", display: "flex" }}
        >
          <Divider />
          <Grid container spacing={3} style={{ marginTop: 8 }}>
            <div
              style={{
                margin: 8,
                padding: 16,
                borderRadius: 4,
                backgroundColor: "#f4f4f4"
              }}
            >
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 8}}>
                <Typography variant="h6" style={{flex: 1}}>{`Deep-link URI for Android and iOS`}</Typography>
                <Button
                  variant="text"
                  color="default"
                  onClick={() => {
                    copy(getItemDeepLinkURI()).catch(error => console.log(error));
                  }}
                >
                  <CopyIcon color="action" />
                </Button>
              </div>
              <Typography color="textSecondary">{getItemDeepLinkURI()}</Typography>
            </div>
            <Grid item xs={12}>
              <form
                style={{ display: "flex", flexWrap: "wrap" }}
                noValidate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Title"
                    multiline
                    rows="4"
                    defaultValue={item.title}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    disabled={!allowEdit}
                    onChange={event => {
                      setTitleInputValue(event.target.value);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    style={{ width: "100%", marginTop: 0 }}
                    disabled={item.title == titleInputValue || !titleInputValue}
                    onClick={() => {
                      const oldTitle = item.title;
                      _setItem({ title: titleInputValue });
                      updateTherapyContentItemTitle(
                        item.id,
                        titleInputValue
                      ).catch(error => {
                        console.log(error);
                        _setItem({ title: oldTitle });
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Language
                  </InputLabel>
                  <Select
                    disabled={!allowEdit}
                    value={item.contentLanguage}
                    onChange={event => {
                      const newValue = event.target.value;
                      setContentItemContentLanguage(item.id, newValue)
                        .then(() => {
                          _setItem({ contentLanguage: newValue });
                        })
                        .catch(error => console.log(error));
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="contentLanguage"
                        id="outlined-content-language-simple"
                      />
                    }
                  >
                    {Object.keys(LANGUAGES_MAP).map(languageKey => {
                      const language = LANGUAGES_MAP[languageKey];
                      return (
                        <MenuItem key={language.code} value={language.code}>
                          {language.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Author
                  </InputLabel>
                  <Select
                    disabled={!allowEdit}
                    value={item.authorId}
                    onChange={event => {
                      const newValue = event.target.value;
                      setContentItemAuthorId(item.id, newValue)
                        .then(() => {
                          _setItem({ authorId: newValue });
                        })
                        .catch(error => console.log(error));
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="authorId"
                        id="outlined-content-language-simple"
                      />
                    }
                  >
                    {Object.keys(data.props.therapyContentAuthors).map(
                      authorId => {
                        const author =
                          data.props.therapyContentAuthors[authorId];
                        return (
                          <MenuItem key={authorId} value={authorId}>
                            {author.defaultName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Target languages</FormLabel>
                  <FormGroup>
                    {Object.keys(LANGUAGES_MAP).map(languageKey => {
                      const language = LANGUAGES_MAP[languageKey];
                      return (
                        <FormControlLabel
                          key={languageKey}
                          control={
                            <Checkbox
                              disabled={!allowEdit}
                              checked={item.targetLanguages[language.code]}
                              onChange={event => {
                                const newValue = event.target.checked;
                                updateContentItemTargetLanguages(
                                  item.id,
                                  language.code,
                                  newValue
                                )
                                  .then(updatedLanguages => {
                                    _setItem({
                                      targetLanguages: updatedLanguages
                                    });
                                  })
                                  .catch(error => console.log(error));
                              }}
                            />
                          }
                          label={language.title}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </form>
              <div
                style={{
                  padding: 16,
                  borderRadius: 8,
                  backgroundColor: "#f4f4f4",
                  margin: 8,
                  maxWidth: 500
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8
                  }}
                >
                  <Typography variant="h6" component="h6">
                    Tags
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%"
                    }}
                  >
                    <TextField
                      id="outlined-dense"
                      label="New tag"
                      margin="dense"
                      variant="outlined"
                      onChange={event => {
                        setNewTagInputValue(event.target.value);
                      }}
                      disabled={!allowEdit}
                      style={{ flex: 1 }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={!allowEdit || !newTagInputValue}
                      onClick={() => {
                        addContentItemTag(item.id, newTagInputValue)
                          .then(updatedTags => {
                            _setItem({ tags: updatedTags });
                          })
                          .catch(error => console.log(error));
                      }}
                    >
                      Add
                      {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                      <AddIcon className={classes.rightIcon} />
                    </Button>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexWrap: "wrap",
                    padding: 8
                  }}
                >
                  {item.tags.map(tag => {
                    return (
                      <Chip
                        key={tag}
                        label={tag}
                        onDelete={
                          allowEdit
                            ? () => {
                                removeContentItemTag(item.id, tag)
                                  .then(updatedTags => {
                                    _setItem({ tags: updatedTags });
                                  })
                                  .catch(error => console.log(error));
                              }
                            : null
                        }
                        className={classes.chip}
                        color="secondary"
                        style={{ backgroundColor: stringToHexColor(tag) }}
                      />
                    );
                  })}
                </div>
              </div>
              {item.contentUrl ? (
                <div
                  style={{
                    maxWidth: 500,
                    margin: 8,
                    marginTop: 16,
                    backgroundColor: "#f4f4f4",
                    borderRadius: 4,
                    padding: 16
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Typography variant="h6" component="h6">
                      File
                    </Typography>
                    <div style={{ height: 8 }} />
                    <Divider />
                    <div style={{ height: 16 }} />
                    <ReactPlayer
                      url={item.contentUrl}
                      controls
                      width="100%"
                      height="100%"
                    />
                    <div style={{ height: 8 }} />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleRemoveFileClick}
                      disabled={!allowEdit || isRemovingFile}
                    >
                      {isRemovingFile ? (
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 16 }}
                        />
                      ) : null}
                      <Typography>Remove</Typography>
                    </Button>
                  </div>
                </div>
              ) : null}
              {allowEdit && !item.contentUrl ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 500,
                    margin: 8
                  }}
                >
                  {fileUploadState && !fileUploadState.uploadFinished ? (
                    <div
                      style={{
                        backgroundColor: "#f4f4f4",
                        padding: 32,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        borderRadius: 4
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <CircularProgress size={24} />
                        <div style={{ width: 16 }} />
                        <Typography>{"Uploading file..."}</Typography>
                      </div>
                      <div style={{ height: 16 }} />
                      <div style={{ width: "100%" }}>
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >{`${localFileName} (${localFileSize} MB)`}</Typography>
                      </div>
                      <div style={{ height: 16 }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={fileUploadState.progress}
                          style={{ flex: 1 }}
                        />
                        <div style={{ width: 16 }} />
                        <Typography>{`${fileUploadState.progress}%`}</Typography>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <FileDrop
                        onDrop={(file, fileName, fileSize) => {
                          console.log(file, fileName, fileSize);
                          setLocalFile(file);
                          setLocalFileName(fileName);
                          setLocalFileSize(fileSize);
                        }}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUploadNewFileClick}
                        disabled={!localFile || !localFileName}
                        style={{ marginTop: 8 }}
                      >
                        Upload file
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}
            </Grid>
          </Grid>
          <div style={{ height: 32 }} />
          <Divider />
          <div style={{ height: 16 }} />
          <div>
            <Button
              color="secondary"
              variant="secondary"
              onClick={() => {
                setAlertDialogState({
                  open: true,
                  title: "Are you sure?",
                  message:
                    "This will remove the item permanently. In order to do that - close this dialog and double-click the button",
                  positiveButtonTitle: "Cancel",
                  negativeButtonTitle: "Delete forever",
                  negativeButtonCallback: handleDeleteItemForeverClick
                });
              }}
              disabled={isRemovingItem}
            >
              Delete Forever
              {isRemovingItem ? (
                <CircularProgress size={20} style={{ marginLeft: 16 }} />
              ) : null}
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <AlertDialog
        open={alertDialogState.open}
        handleClose={() => setAlertDialogState({ open: false })}
        title={alertDialogState.title}
        message={alertDialogState.message}
        positiveButtonTitle={alertDialogState.positiveButtonTitle}
        positiveButtonCallback={alertDialogState.positiveButtonCallback}
        negativeButtonTitle={alertDialogState.negativeButtonTitle}
        negativeButtonCallback={alertDialogState.negativeButtonCallback}
      />
    </Paper>
  );
}

function Content(data) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  return (
    <div className={classes.root}>
      {data.props.isLoadingTherapyContent ? (
        <CircularProgress />
      ) : data.props.isFetchTherapyContentFailed ? (
        "Error"
      ) : (
        <List className={classes.list}>
          {data.props.therapyContentItems.filter((item) => {
            const searchValue = data.searchValue ? data.searchValue.toLowerCase() : "";
            return searchValue
              ? (item.title &&
                  item.title.toLowerCase().includes(searchValue)) ||
                  (item.author &&
                    item.author.defaultName &&
                    item.author.defaultName.toLowerCase().includes(searchValue))  ||
                    item.tags && item.tags.includes(searchValue)
              : true;
          }).map(item => {
            return <Item item={item} key={item.title} props={data.props}/>;
          })}
        </List>
      )}
    </div>
  );
}

function AlertDialog(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.negativeButtonTitle && props.negativeButtonCallback ? <Button
            onClick={props.negativeButtonCallback || props.handleClose}
            color="secondary"
            autoFocus
          >
            {props.negativeButtonTitle}
          </Button> : null}
          <Button
            onClick={props.positiveButtonCallback || props.handleClose}
            color="primary"
            autoFocus
          >
            {props.positiveButtonTitle || "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function FileDrop(props) {
  
  const [fileName, setFileName] = React.useState("");
  const [fileSize, setFileSize] = React.useState(0);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      const reader = new FileReader();
      
      let file = acceptedFiles[0];

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        console.log(file);
        setFileName(file.name);
        setFileSize(file.size / 1000000);
        props.onDrop(reader.result, file.name, parseInt(file.size / 1000000, 10));
      };
      reader.readAsArrayBuffer(file);
    }
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} style={{padding: 16, backgroundColor: "#f4f4f4", borderRadius: 8}}>
      <input {...getInputProps()} />
      <p>{fileName && fileSize ? `${fileName} - ${parseInt(fileSize, 10)} MB` : "Drag 'n' drop the file here, or click to select file"}</p>
    </div>
  )
}

const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const createNewItemDialogStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function CreateNewItemDialog(props) {

  const classes = createNewItemDialogStyles();

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={CreateNewItemDialogTransition}>
        <AddNewItemForm handleClose={props.handleClose} onShouldFetchItems={props.onShouldFetchItems} props={props.props}/>
      </Dialog>
    </div>
  );
}

function AddNewItemForm(props) {

  const MEDIA_TYPES = ["video"];

  const classes = useStyles();
  const appBarClasses = createNewItemDialogStyles();

  const [alertDialogState, setAlertDialogState] = React.useState({
    open: false,
  });

  const [titleInputValue, setTitleInputValue] = React.useState("");
  const [authorIdInputValue, setAuthorIdInputValue] = React.useState("");
  const [contentLanguageInputValue, setContentLanguageInputValue] = React.useState("");
  const [mediaTypeInputValue, setMediaTypeInputValue] = React.useState(MEDIA_TYPES[0]);
  const [targetLanguagesInputValue, setTargetLanguagesInputValue] = React.useState({});
  const [tagsInputValue, setTagsInputValue] = React.useState([]);
  const [newTagInputValue, setNewTagInputValue] = React.useState("");
  const [fileLocalDetails, setFileLocalDetails] = React.useState(null);
  const [localFile, setLocalFile] = React.useState(null);
  const [localFileName, setLocalFileName] = React.useState("");
  const [localFileSize, setLocalFileSize] = React.useState(0);
  const [fileUploadState, setFileUploadedState] = React.useState(null);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function createItem() {
    if (isAllowToCreate()) {
      createTherapyContentItem(titleInputValue, contentLanguageInputValue, targetLanguagesInputValue, tagsInputValue, mediaTypeInputValue, authorIdInputValue).then((item) => {
        uploadTherapyContentFile(item.ref, localFile, localFileName, (progress) => {
          console.log(progress);
          setFileUploadedState(Object.assign({}, {progress: progress, uploadFinished: false}));
        }, () => {
          console.log("upload finished - success callback");
          props.onShouldFetchItems();
          props.handleClose();
        }, () => {
          console.log("upload error - callback");
          setFileUploadedState(null);
        }).then(() => {
          console.log("upload finished - then");
        }).catch((error) => {
          console.log("upload error - catch", error);
          setFileUploadedState(null);
        });
      }).catch(error => {
        console.log(error);
        setFileUploadedState(null);
      });
    } else {
      setAlertDialogState({
        open: true, title: "Missing or invalid input", message: "Please make sure your input is complete and valid"
      });
    }
  }

  function isAllowToCreate() {
    return titleInputValue && mediaTypeInputValue && contentLanguageInputValue && targetLanguagesInputValue && localFile && localFileName && tagsInputValue && authorIdInputValue;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
      className={classes.root}
    >
      <AlertDialog
        open={alertDialogState.open}
        handleClose={() => setAlertDialogState({ open: false })}
        title={alertDialogState.title}
        message={alertDialogState.message}
      />
      <AppBar className={appBarClasses.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={appBarClasses.title}>
            Add new item
          </Typography>
          <Button variant="contained" onClick={createItem}>
            Create
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ backgroundColor: "white", padding: 16, height: "100%" }}>
        {/* <Grid>
          <Typography variant="button" component="h6">
            Add new item
          </Typography>
          <Divider />
        </Grid> */}
        {fileUploadState && !fileUploadState.uploadFinished ? (
          <div
            style={{
              padding: 32,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              borderRadius: 4
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress size={24} />
              <div style={{ width: 16 }} />
              <Typography>{"Uploading file..."}</Typography>
            </div>
            <div style={{ height: 16 }} />
            <div style={{ width: "100%" }}>
              <Typography
                style={{ width: "100%", textAlign: "center" }}
              >{`${localFileName} (${localFileSize} MB)`}</Typography>
            </div>
            <div style={{ height: 16 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <LinearProgress
                variant="determinate"
                value={fileUploadState.progress}
                style={{ flex: 1 }}
              />
              <div style={{ width: 16 }} />
              <Typography>{`${fileUploadState.progress}%`}</Typography>
            </div>
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <form
                style={{ display: "flex", flexWrap: "wrap" }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Title"
                  multiline
                  rows="4"
                  defaultValue={""}
                  placeholder={"The item's title"}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onChange={event => {
                    setTitleInputValue(event.target.value);
                  }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Language
                  </InputLabel>
                  <Select
                    value={contentLanguageInputValue}
                    onChange={event => {
                      setContentLanguageInputValue(event.target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="contentLanguage"
                        id="outlined-content-language-simple"
                      />
                    }
                  >
                    {Object.keys(LANGUAGES_MAP).map(languageKey => {
                      const language = LANGUAGES_MAP[languageKey];
                      return (
                        <MenuItem key={language.code} value={language.code}>
                          {language.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Author
                  </InputLabel>
                  <Select
                    value={authorIdInputValue}
                    onChange={event => {
                      setAuthorIdInputValue(event.target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="authorId"
                        id="outlined-content-language-simple"
                      />
                    }
                  >
                    {Object.keys(props.props.therapyContentAuthors).map(
                      authorId => {
                        const author =
                          props.props.therapyContentAuthors[authorId];
                        return (
                          <MenuItem key={authorId} value={authorId}>
                            {author.defaultName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                    Media Type
                  </InputLabel>
                  <Select
                    value={mediaTypeInputValue}
                    onChange={event => {
                      setMediaTypeInputValue(event.target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="contentLanguage"
                        id="outlined-content-language-simple"
                      />
                    }
                  >
                    {MEDIA_TYPES.map(mediaType => {
                      return (
                        <MenuItem key={mediaType} value={mediaType}>
                          {mediaType.toLocaleUpperCase()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Target languages</FormLabel>
                  <FormGroup>
                    {Object.keys(LANGUAGES_MAP).map(languageKey => {
                      const language = LANGUAGES_MAP[languageKey];
                      return (
                        <FormControlLabel
                          key={languageKey}
                          control={
                            <Checkbox
                              checked={targetLanguagesInputValue[language.code]}
                              onChange={event => {
                                setTargetLanguagesInputValue(
                                  Object.assign({}, targetLanguagesInputValue, {
                                    [language.code]: event.target.checked
                                  })
                                );
                              }}
                            />
                          }
                          label={language.title}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FileDrop
                    onDrop={(file, fileName, fileSize) => {
                      // setFileLocalDetails(Object.assign({}, file));
                      setLocalFile(file);
                      setLocalFileName(fileName);
                      setLocalFileSize(fileSize);
                    }}
                  />
                </div>
              </form>
              <div
                style={{
                  padding: 16,
                  borderRadius: 8,
                  backgroundColor: "#f4f4f4",
                  margin: 8,
                  maxWidth: 500
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8
                  }}
                >
                  <Typography variant="h6" component="h6">
                    Tags
                  </Typography>
                  <div>
                    <TextField
                      id="outlined-dense"
                      label="New tag"
                      margin="dense"
                      variant="outlined"
                      onChange={event => {
                        setNewTagInputValue(event.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={!newTagInputValue}
                      onClick={() => {
                        setTagsInputValue([
                          ...tagsInputValue,
                          newTagInputValue
                        ]);
                        setNewTagInputValue("");
                      }}
                    >
                      Add
                      {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                      <AddIcon className={classes.rightIcon} />
                    </Button>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexWrap: "wrap",
                    padding: 8
                  }}
                >
                  {tagsInputValue.map(tag => {
                    return (
                      <Chip
                        key={tag}
                        label={tag}
                        onDelete={() => {
                          let newTags = [...tagsInputValue];
                          newTags = newTags.filter(currTag => {
                            return currTag !== tag;
                          });
                          setTagsInputValue(newTags);
                        }}
                        className={classes.chip}
                        color="secondary"
                        style={{ backgroundColor: stringToHexColor(tag) }}
                      />
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

function SearchBar(props) {
  const classes = useStyles();
  return (<div className={classes.search}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      placeholder="Search…"
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      inputProps={{ 'aria-label': 'search' }}
      onChange={(event) => {
        props.onInputChange(event.target.value);
      }}
    />
  </div>);
}

class TherapyContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      addNewItemMode: false,
      searchValue: "",
    };
  }
  componentDidMount() {
    if (!this.props.therapyContentItems || this.props.therapyContentItems.length === 0) {
      this.props.fetchTherapyContent();
    }
  }

  render() {
    return (
      <div style={{ flex: 1, height: "100%", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
          {/* <div style={{ flex: 1 }} /> */}
          <SearchBar
            onInputChange={value => {
              this.setState({
                searchValue: value
              });
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            component="span"
            color={this.state.addNewItemMode ? "secondary" : "primary"}
            onClick={() => {
              this.setState({ addNewItemMode: !this.state.addNewItemMode });
            }}
          >
            {this.state.addNewItemMode ? "Cancel" : "Add new item"}
          </Button>
          {this.state.addNewItemMode ? (
            <Button
              variant="contained"
              component="span"
              color={"primary"}
              style={{ marginLeft: 16 }}
            >
              Save
            </Button>
          ) : null}
        </div>
        <Content props={this.props} searchValue={this.state.searchValue} />
        <CreateNewItemDialog
          open={this.state.addNewItemMode}
          handleClose={() => {
            this.setState({
              addNewItemMode: false
            });
          }}
          onShouldFetchItems={this.props.fetchTherapyContent}
          props={this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ therapyContent }) => ({
  therapyContentItems: therapyContent.items,
  therapyContentAuthors: therapyContent.authors,
  isLoadingTherapyContent: therapyContent.isLoadingTherapyContent,
  isFetchTherapyContentFailed: therapyContent.isFetchTherapyContentFailed
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTherapyContent
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TherapyContent);
