import React from "react";
import { Dialog,  Slide } from "@material-ui/core";
import YoutubeItemForm from "./YoutubeItemForm"

const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateYouTubeItemDialog({open, handleClose, onShouldFetchItems, formProps}) {
    return (
            <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
                <YoutubeItemForm handleClose={handleClose} onShouldFetchItems={onShouldFetchItems} props={formProps}/>
            </Dialog>
    );
}