import { flow, set, get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import _ from "lodash"

export const FETCH_ORGANIZATIONS_REQUESTED = "organizations/FETCH_ORGANIZATIONS_REQUESTED";
export const FETCH_ORGANIZATIONS_SUCCESS = "organizations/FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAILED = "organizations/FETCH_ORGANIZATIONS_FAILED";

export const CREATE_ORGANIZATION_REQUESTED = "organizations/CREATE_ORGANIZATION_REQUESTED";
export const CREATE_ORGANIZATION_SUCCESS = "organizations/CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAILED = "organizations/CREATE_ORGANIZATION_FAILED";

export const ADD_ORGANIZATION = "organizations/ADD_ORGANIZATION";
export const DELETE_ORGANIZATION = "organizations/DELETE_ORGANIZATION";

const initialState = {
    organizations: [],
    isLoading: false,
    isFetchFailed: false,
    filtered: [],
    uploading: false,
    uploadError: false
};

const addOrganizationHelper = (organizations, organization) => {
    let clonedOrganizations = _.cloneDeep(organizations)
    clonedOrganizations.push(organization)
    return clonedOrganizations
}

const deleteOrganizationHelper = (organizations, id) => {
    return organizations.filter(o => o.id !== id)
}

export default handleActions({
    [FETCH_ORGANIZATIONS_REQUESTED]: (state, action) => flow([
        set("isLoading", true),
        set("isFetchFailed", false)
    ])(state),

    [FETCH_ORGANIZATIONS_SUCCESS]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", false),
        set("organizations", action.payload.orgs),
    ])(state),

    [FETCH_ORGANIZATIONS_FAILED]: (state, action) => flow([
        set("isLoading", false),
        set("isFetchFailed", true)
    ])(state),

    [CREATE_ORGANIZATION_REQUESTED]: (state, action) => flow([
        set("uploading", true),
        set("uploadError", false)
    ])(state),

    [CREATE_ORGANIZATION_SUCCESS]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", false),
    ])(state),

    [CREATE_ORGANIZATION_FAILED]: (state, action) => flow([
        set("uploading", false),
        set("uploadError", true)
    ])(state),

    [ADD_ORGANIZATION]: (state, action) => flow([
        set("organizations", addOrganizationHelper(state.organizations, action.payload.organization)),
    ])(state),

    [DELETE_ORGANIZATION]: (state, action) => flow([
        set("organizations", deleteOrganizationHelper(state.organizations, action.payload.id)),
    ])(state),


}, initialState);

export const addOrgToState = (org) => {
    return dispatch => {
        dispatch({
            type: ADD_ORGANIZATION,
            payload: {
                organization: org
            }
        });
    }
}

export const deleteOrgFromState = (id) => {
    return dispatch => {
        dispatch({
            type: DELETE_ORGANIZATION,
            payload: {
                id
            }
        });
    }
}



