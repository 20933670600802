import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";
import YoutubeSong from "./YoutubeSong"
import { getDefaultSong } from "../../modules/youtubeSongs";
import { fetchYoutubeSongs } from "../../api/youtubeSongs";

import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

function PaperDropzone({ onSubmit, uploading, uploadError }) {

    const onDrop = useCallback((acceptedFiles) => {
        console.log("acceptedFiles", acceptedFiles)
    }, [])


    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'application/vnd.ms-excel, text/csv, text/x-csv',
        onDrop,
        maxFiles: 1
    });

    const { ref, ...rootProps } = getRootProps()

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));


    return (
        <RootRef rootRef={ref} style={{ padding: 50 }}>
            <Paper {...rootProps} style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <Fragment>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop csv file here, or click to select file</p>
                            <em>(Only *.csv)</em>
                        </div>
                        <aside>
                            <h4>Accepted files</h4>
                            <ul>{acceptedFileItems}</ul>
                            <h4>Rejected files</h4>
                            <ul>{fileRejectionItems}</ul>
                        </aside>
                    </Fragment>
                )}
                {uploadError && (<div style={{ color: "red" }}>error uploading file</div>)}
            </Paper>

            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={acceptedFiles.length < 1 || uploading}
                onClick={() => {
                    onSubmit(acceptedFiles[0])
                }}
            >
                Submit
            </Button>
        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateYouTubeItemDialog({ open, handleClose, onShouldFetchItems, formProps, onSubmit, uploading, uploadError }) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <PaperDropzone onSubmit={onSubmit} uploading={uploading} uploadError={uploadError} handleClose={handleClose} />
        </Dialog>
    );
}

