import React from "react";
import {Button, Dialog, Slide, CircularProgress, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import {
    TextField,
} from "@material-ui/core";

import { fade, makeStyles } from "@material-ui/core/styles";
import {UNIT_TYPES} from "../SdkOrganizations";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

function DialogForm({ onSubmit, uploading, unitsLimit, setUnitsLimit,startDate,setStartDate, endDate, setEndDate}) {

    const classes = useStyles();

    const [error, setError] = React.useState("");

    const validate = () => {
        let isValid = true;

        // validate start date is before end date if both are set
        if (startDate && endDate) {
            if (startDate > endDate) {
                setError("Start date must be before end date")
                isValid = false;
            }
        }else {
            setError("Please set start and end date")
            isValid = false;
        }

        return isValid;
    }

    const today = new Date()
    const nextMonth = new Date(today)
    nextMonth.setMonth(today.getMonth() + 1)


    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <form
                        style={{ display: "flex", flexWrap: "wrap" }}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField
                            id="outlined-dense"
                            label="Units Limit"
                            margin="dense"
                            variant="outlined"
                            type={"number"}
                            value={unitsLimit}
                            onChange={event => {
                                setUnitsLimit(event.target.value);
                            }}
                        />

                        <TextField
                            id="date"
                            label="StartDate"
                            type="date"
                            defaultValue={today}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={event => {
                                console.log("setting start date", event.target.value)
                                setStartDate(event.target.value)
                            }}
                        />
                        <TextField
                            id="date"
                            label="End Date"
                            type="date"
                            defaultValue={nextMonth}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={event => {
                               setEndDate(event.target.value)
                            }}
                        />
                    </form>

                )}

                {error}

            </Paper>

            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={uploading}
                onClick={() => {
                    if (!validate()) {
                        return;
                    }
                    setError("")
                    onSubmit()
                }}
            >
                Submit
            </Button>


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UnitsLimitDialog({ open, handleClose, formProps, onSubmit, uploading, unitsLimit, setUnitsLimit,startDate,setStartDate, endDate, setEndDate }) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <DialogForm
                onSubmit={onSubmit}
                uploading={uploading}
                handleClose={handleClose}
                unitsLimit={unitsLimit}
                setUnitsLimit={setUnitsLimit}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        </Dialog>
    );
}

