import React from "react";
import { useStyles } from "./styles";
import SearchIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { InputBase } from "@material-ui/core";

const SearchBar = ({onInputChange}) =>{
    const classes = useStyles();
    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Search…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={event => {
                    onInputChange(event.target.value);
                }}
            />
        </div>
    );
}

export default SearchBar;