import React, { Component, useCallback } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchYoutubePlaylists, setYoutubePlaylistsSearchQuery } from "../../modules/youtubePlaylists";

import {
  CircularProgress,
  List,
  Button,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";


import {useStyles} from "../common/styles"
import SearchBar from "../common/SearchBar"
import YoutubeListItem from "../common/YoutubeListItem"
import CreateYouTubeItemDialog from "../common/CreateYoutubeItemDialog"
  
function Content(data) {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        {data.props.isLoadingYoutubePlaylists ? (
          <CircularProgress />
        ) : data.props.isFetchYoutubePlaylistsFailed ? (
          "Error"
        ) : (
          <List className={classes.list} >
            {(data.props.searchQuery && !data.props.isLoadingSearch ? data.props.filteredPlaylists : data.props.playlists).map(item => {
              return <YoutubeListItem item={item} key={item.ref} props={data.props}/>;
            })}
          </List>
        )}
      </div>
    );
  }

class YoutubePlaylists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addNewItemMode: false,
    };
  }

  componentDidMount() {
    if (!this.props.playlists || this.props.playlists.length === 0) {
      this.props.fetchYoutubePlaylists();
    }
  }

  componentWillUnmount() {
    if (this.searchInputTimeoutHandler) {
      clearTimeout(this.searchInputTimeoutHandler);
    }
  }

  render() {
    return (
      <div style={{ flex: 1, height: "100%", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
          {/* <div style={{ flex: 1 }} /> */}
          <SearchBar
            onInputChange={value => {
              if (this.searchInputTimeoutHandler) {
                clearTimeout(this.searchInputTimeoutHandler);
              }
              this.searchInputTimeoutHandler = setTimeout(() => {
                this.props.setYoutubePlaylistsSearchQuery(
                  value,
                  this.props.playlists
                );
              }, 2000);
            }}
          />
          <Typography style={{ marginLeft: 16, marginRight: 16 }}>{`${
            this.props.filteredPlaylists &&
            this.props.filteredPlaylists.length > 0
              ? this.props.filteredPlaylists.length
              : this.props.playlists.length
          } playlists`}</Typography>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            component="span"
            color={this.state.addNewItemMode ? "secondary" : "primary"}
            onClick={() => {
              this.setState({ addNewItemMode: !this.state.addNewItemMode });
            }}
          >
            {this.state.addNewItemMode ? "Cancel" : "Add new item"}
          </Button>
          {this.state.addNewItemMode ? (
            <Button
              variant="contained"
              component="span"
              color={"primary"}
              style={{ marginLeft: 16 }}
            >
              Save
            </Button>
          ) : null}
        </div>
        <Content props={this.props} />
        <CreateYouTubeItemDialog
          open={this.state.addNewItemMode}
          handleClose={() => {
            this.setState({
              addNewItemMode: false
            });
          }}
          onShouldFetchItems={this.props.fetchYoutubePlaylists}
          formProps={this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ youtubePlaylists }) => ({
  playlists: youtubePlaylists.playlists,
  filteredPlaylists: youtubePlaylists.filteredPlaylists,
  isLoadingYoutubePlaylists: youtubePlaylists.isLoadingYoutubePlaylists || youtubePlaylists.isLoadingSearch,
  isFetchYoutubePlaylistsFailed: youtubePlaylists.isFetchYoutubePlaylistsFailed,
  searchQuery: youtubePlaylists.searchQuery,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchYoutubePlaylists,
      setYoutubePlaylistsSearchQuery,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubePlaylists);
