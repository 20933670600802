import React from "react";
import {
    Button,
    Dialog,
    FormControl,
    Grid,
    IconButton, InputLabel,
    makeStyles, MenuItem, OutlinedInput, Select,
    Slide,
    TextField,
    Toolbar
} from "@material-ui/core";
import { useStyles, createNewItemDialogStyles } from "./styles";
import { createPlaylist } from "../../api/youtubePlaylists";
import AlertDialog from "./AlertDialog";
import AppBar from "@material-ui/core/AppBar/AppBar";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { ENERGIES, PERIODS } from "../../api/therapyContent";


export default function YoutubeItemForm(props) {

    const classes = useStyles();
    const appBarClasses = createNewItemDialogStyles();

    const [alertDialogState, setAlertDialogState] = React.useState({
        open: false,
    });

    const [periodInputValue, setPeriodInputValue] = React.useState("");
    const [energyInputValue, setEnergyInputValue] = React.useState("");
    const [languageInputValue, setLanguageInputValue] = React.useState("");
    const [styleInputValue, setStyleInputValue] = React.useState("");
    const [urlInputValue, setUrlInputValue] = React.useState("");

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    function createItem() {
        if (isAllowToCreate()) {
            createPlaylist(
                urlInputValue,
                styleInputValue,
                languageInputValue,
                periodInputValue,
                energyInputValue
            )
                .then(() => {
                    props.onShouldFetchItems();
                    props.handleClose();
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            setAlertDialogState({
                open: true,
                title: "Missing or invalid input",
                message: "Please make sure your input is complete and valid"
            });
        }
    }

    function isAllowToCreate() {
        return urlInputValue && periodInputValue && energyInputValue && (styleInputValue || languageInputValue);
    }

    return (
        <div
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            className={classes.root}
        >
            <AlertDialog
                open={alertDialogState.open}
                handleClose={() => setAlertDialogState({ open: false })}
                title={alertDialogState.title}
                message={alertDialogState.message}
            />
            <AppBar className={appBarClasses.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={appBarClasses.title}>
                        Add new item
                    </Typography>
                    <Button variant="contained" onClick={createItem}>
                        Create
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={{ backgroundColor: "white", padding: 16, height: "100%" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <form
                            style={{ display: "flex", flexWrap: "wrap" }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label="URL"
                                multiline
                                rows="4"
                                defaultValue={""}
                                placeholder={"The youtube playlist URL"}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={event => {
                                    setUrlInputValue(event.target.value);
                                }}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="Style"
                                multiline
                                rows="4"
                                defaultValue={""}
                                placeholder={"The playlist style"}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                disabled={languageInputValue ? true : false}
                                onChange={event => {
                                    setStyleInputValue(event.target.value);
                                }}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="Language"
                                multiline
                                rows="4"
                                defaultValue={""}
                                placeholder={"The playlist language"}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                disabled={styleInputValue ? true : false}
                                onChange={event => {
                                    setLanguageInputValue(event.target.value);
                                }}
                            />
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                                    Energy
                                </InputLabel>
                                <Select
                                    value={energyInputValue}
                                    onChange={event => {
                                        setEnergyInputValue(event.target.value);
                                    }}
                                    input={
                                        <OutlinedInput
                                            labelWidth={labelWidth}
                                            name="energy"
                                            id="outlined-energy-simple"
                                        />
                                    }
                                >
                                    {ENERGIES.map(energy => {
                                        return (
                                            <MenuItem key={energy} value={energy}>
                                                {energy}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                                    Period
                                </InputLabel>
                                <Select
                                    value={periodInputValue}
                                    onChange={event => {
                                        setPeriodInputValue(event.target.value);
                                    }}
                                    input={
                                        <OutlinedInput
                                            labelWidth={labelWidth}
                                            name="period"
                                            id="outlined-period-simple"
                                        />
                                    }
                                >
                                    {PERIODS.map(period => {
                                        return (
                                            <MenuItem key={period} value={period}>
                                                {period}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}