import firebase from 'firebase/compat/app';

// Add the Firebase services that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FIRESTORE_COLLECTION_NAMES } from "./constants";

const QA_CONFIG = {
    apiKey: "AIzaSyBS02nFXdJHjkozV1hrTJJb3bNGtot6J7U",
    authDomain: "app-data-c924b.firebaseapp.com",
    databaseURL: "https://app-data-c924b.firebaseio.com",
    projectId: "app-data-c924b",
    storageBucket: "app-data-c924b.appspot.com",
    messagingSenderId: "389314820136",
    appId: "1:389314820136:web:cd923665b5667eb6"
  };

  const PROD_CONFIG = {
    apiKey: "AIzaSyDtICe_AlbF_exnciwZUNblzEZfUpQR6zI",
    authDomain: "imagine-163807.firebaseapp.com",
    databaseURL: "https://imagine-163807.firebaseio.com",
    projectId: "imagine-163807",
    storageBucket: "imagine-163807.appspot.com",
    messagingSenderId: "925739218829",
    appId: "1:925739218829:web:6355677d1a0a39eff1efc4"
  };

export var firebaseConfig = PROD_CONFIG//!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? QA_CONFIG : PROD_CONFIG;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();

export function setAuthStateChangedListener(callback) {
    return firebase.auth().onAuthStateChanged(callback);
}

export async function signOut() {
    return firebase.auth().signOut();
}

export async function checkIfEmailAllowToSignInWithGoogle(email) {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
    let result =
      signInMethods &&
      signInMethods.includes(
        firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
      );
    return true;
}

export async function signInWithGoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        hd: "imsolo.ai"
    });
    try {
        const result = await firebase.auth().signInWithPopup(provider);
        console.log("res", result)
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        return result;
    } catch (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.error("error", error)
    }
}

export function currentUser() {
    return firebase.auth().currentUser;
}
