import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import _ from "lodash"

import { Button, CircularProgress, List } from "@material-ui/core";
import CreateHouseDialog, {BooleanField, HouseConfigVersion} from "./CreateHouseDialog";
import CreateHouseAdminDialog from "./CreateHouseAdminDialog"
import AttachOrganizationDialog from "./AttachOrganizationDialog";
import { useStyles } from "../common/styles";

import { addHouseToState, deleteHouseFromState } from "../../modules/houses";

import { createHouse, updateHouse, fetchHouses, createHouseAdmin, deleteHouseAdmin, deleteHouseOrganization, deleteHouse, getHouseLink, setHouseConfigVersion } from "../../api/house";
import Table, { NumberRangeColumnFilter } from "../Table/Table";
import CreateAdminsEditDialog from "./AdminsEditDialog"
import CloseIcon from '@material-ui/icons/Close';
import { ConfirmationDialogRaw } from "../ConfirmDelete"

import {
    getOrganizations,
    addOrganizationToHouse
} from "../../api/organization"

import EditableField from "../EditableField";

import {
    HouseName,
    HouseCountry,
    HouseType,
    HouseMaxResidents,
} from "./CreateHouseDialog"
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";



const adminAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => item.email) : [];
    let join = map.join();
    return join
}


function Content({
    addNewItemMode,
    setAddNewItemMode,
    isLoading,
    isFetchFailed,
    houses,
    createAdmin,
    createOrganization,
    removeLocalHouseAdmin,
    removeLocalHouseOrganizarion,
    editNameValidation,
    onRemoveItem,
}) {
    const classes = useStyles();

    const [admins, setAdmins] = React.useState([]);
    const [adminsDialogOpen, setAdminsDialogOpen] = React.useState(false);
    const [adminsRes, setAdminsRes] = React.useState(null);
    const [savingAdmins, setAsSvingAdmins] = React.useState(false);
    const [savingAdminsError, setAsSvingAdminsError] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [selected, setSelected] = React.useState(null);


    const handleClose = async (shouldDelete = false) => {
        setOpen(false);
        if (shouldDelete) {
            let res = await onRemoveItem(selected);
            console.log("delete", res)
        }
        setSelected(null)
        setMessage("")
    };

    const resetAdminsDialog = () => {
        setAdmins([])
        setAdminsDialogOpen(false)
        setAdminsRes(null)
        setAsSvingAdmins(false)
        setAsSvingAdminsError(null)
    }

    const onDeleteAdmin = async (id) => {
        try {
            setAsSvingAdmins(true)
            await deleteHouseAdmin({ id })
            setAsSvingAdmins(false)
        } catch (e) {
            setAsSvingAdmins(false)
            setAsSvingAdminsError(e)
        }

    }


    const onDeleteOrganization = async (houseId, orgName, houseName) => {
        try {
            if (window.confirm(`Do you really want to remove ${houseName} from ${orgName} organization?`)) {
                await deleteHouseOrganization({ id: houseId })
                removeLocalHouseOrganizarion(houseId)
            }

        } catch (e) {
            console.error("onDeleteOrganization", e)
        }

    }

    const updateField = async ({ id, key, value }) => {
        console.log("updating house", { id, key, value })
        let payload = { id }
        payload[key] = value
        let res = await updateHouse(payload)
        console.log("res", res)
    }


    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={HouseName} onSave={(value) => {
                    if (editNameValidation(value)) {
                        window.alert("house with the same name already exist in the system")
                        return false;
                    } else {
                        updateField({ id: row.original.id, key: "name", value })
                    }

                }} />
            }
        },
        {
            Header: 'House Key',
            accessor: 'key',
            filter: 'fuzzyText',
        },
        {
            Header: 'Max Residents',
            accessor: 'maxResidents',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={HouseMaxResidents} onSave={(value) => {
                    updateField({ id: row.original.id, key: "maxResidents", value })
                }} />
            }
        },
        {
            Header: 'Country',
            accessor: 'country',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={HouseCountry} onSave={(value) => {
                    updateField({ id: row.original.id, key: "country", value })
                }} />
            }
        },
        {
            Header: 'Type',
            accessor: 'type',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={HouseType} onSave={(value) => {
                    updateField({ id: row.original.id, key: "type", value })
                }} />
            }
        },
        {
            Header: 'Active',
            accessor: 'active',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={BooleanField} boolean={true} onSave={(value) => {
                    updateField({ id: row.original.id, key: "active", value })
                }} />
            }
        },
        {
            Header: 'Use Legacy',
            accessor: 'useLegacyVersion',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField value={value} renderInput={BooleanField} boolean={true} onSave={(value) => {
                    updateField({ id: row.original.id, key: "useLegacyVersion", value })
                }} />
            }
        },
        {
            Header: 'Config Version',
            accessor: 'configVersion',
            filter: 'fuzzyText',
            Cell: ({ row, value }) => {
                return <EditableField placeholder={"N/A"} value={value} renderInput={HouseConfigVersion} onSave={(value) => {
                    if(!value){
                        value = null;
                    }
                    updateField({ id: row.original.id, key: "configVersion", value })
                }} />
            }
        },
        {
            Header: 'Organization',
            accessor: (originalRow, rowIndex) => originalRow.Organization ? originalRow.Organization.name : "",
            filter: 'fuzzyText',
            Cell: ({ value, row }) => {
                if (value) {
                    return (
                        <div>
                            {value}
                            <CloseIcon style={{ cursor: "pointer" }} onClick={() => { onDeleteOrganization(row.original.id, value, row.original.name) }} />
                        </div>
                    )
                } else {
                    return (
                        <Button
                            onClick={() => {
                                //house id - value.id
                                console.log("create org clicked", row.original.id)
                                createOrganization(row.original.id)
                            }}
                        >
                            {"Add Org."}
                        </Button>
                    )
                }
            }
        },
        {
            Header: 'Admins',
            id: "Admins",
            filter: 'fuzzyText',
            accessor: (originalRow, rowIndex) => adminAccessor(originalRow, rowIndex, "HouseAdmins"),
            Cell: ({ row, value }) => {
                return (
                    <EditableField
                        value={value}
                        onEdit={() => {
                            setAdminsDialogOpen(true)
                            setAdmins(row.original.HouseAdmins)
                        }}
                        onSave={async (id) => {
                            console.log("save", id)

                        }}
                        renderInput={
                            ({ value, setValue, onSave }) => {
                                return <CreateAdminsEditDialog
                                    result={adminsRes}
                                    admins={admins}
                                    handleClose={resetAdminsDialog}
                                    onSubmit={async (id) => {
                                        await onDeleteAdmin(id)
                                        onSave()

                                        removeLocalHouseAdmin(row.original.id, id)
                                    }}
                                    uploading={savingAdmins}
                                    uploadError={savingAdminsError}
                                    open={adminsDialogOpen}
                                />
                            }
                        }
                        fieldMaxWidth={160}
                    />
                )
            }
        },
        {
            id: "actions",
            Header: "",
            accessor: (originalRow, rowIndex) => ({ id: originalRow.id }),
            filter: null,
            Filter: () => null,
            Cell: ({ row, value }) => (
                <div>
                    <Button
                        onClick={() => {
                            //house id - value.id
                            console.log("create admin clicked", value.id)
                            createAdmin(value.id)
                        }}
                    >
                        {"Add Admin"}
                    </Button>

                    <Button
                        onClick={async () => {
                            //house id - value.id
                            console.log("create admin clicked", value.id)
                            let link = await getHouseLink(row.original.key)
                            console.log("link", link)
                            if (link && link.shortLink) {
                                let tempInput = document.createElement("input");
                                tempInput.value = link.shortLink;
                                document.body.appendChild(tempInput);
                                tempInput.select();
                                document.execCommand("copy");
                                document.body.removeChild(tempInput);
                                alert(`link ${link.shortLink} has copied to clipboard successfully`)
                            }

                        }}
                    >
                        {"Copy House Link"}
                    </Button>
                </div>

            )
        },
        {
            id: "actions2",
            Header: "",
            accessor: (originalRow, rowIndex) => ({ id: originalRow.id, name: originalRow.name }),
            filter: null,
            Filter: () => null,
            Cell: ({ value }) => (
                <IconButton color="primary" aria-label="delete" component="span" onClick={() => {
                    console.log("delete", value.id)
                    setMessage(`Are you sure you want to delete "${value.name}"?`)
                    setOpen(true)
                    setSelected(value.id)
                }}>
                    <Delete />
                </IconButton>
            )
        }
    ]
    return (
        <div className={classes.root}>

            <Button
                variant="contained"
                component="span"
                color={addNewItemMode ? "secondary" : "primary"}
                onClick={() => {
                    setAddNewItemMode(!addNewItemMode)
                }}
            >
                {addNewItemMode ? "Cancel" : "Create House"}
            </Button>
            {addNewItemMode ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    style={{ marginLeft: 16 }}
                >
                    Save
                </Button>
            ) : null}

            {isLoading ? (
                <CircularProgress />
            ) : isFetchFailed ? (
                "Error"
            ) : (
                <Table
                    columns={columns}
                    data={houses}
                />
            )}

            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                message={message}
                title={"Confirm Delete"}
            />
        </div>
    );
}

class Houses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewItemMode: false,
            addAdminMode: false,
            createOrganizationOpen: false,

            uploading: false,
            uploadError: false,
            createdHouse: null,
            adminHouseId: null,
            createdOrg: null,
            createdAdmin: null,
            editRow: null

        };
    }

    componentDidMount() {
        this.props.fetchHouses()
        this.props.getOrganizations()
    }

    closeCreateAdminDialog = () => {
        this.setState({
            addAdminMode: false,
            adminHouseId: null,
            createdAdmin: null
        })
    }

    submit = async (house) => {
        console.log("saving house", house)
        let { organizations } = this.props
        try {
            this.setState({ uploading: true })
            let res = await createHouse(house)
            let newHouse = res.house
            let houseAdminRes, organization
            if (house.org) {
                await addOrganizationToHouse(house.org, res.house.id)
                newHouse.Organization = {}
                organization = organizations.find(o => o.id === house.org)
                if (organization) {
                    newHouse.Organization.name = organization.name
                }
            }
            if (house.adminEmail && house.adminName) {
                houseAdminRes = await createHouseAdmin({ name: house.adminName, email: house.adminEmail, houseId: res.house.id })
                newHouse.HouseAdmins = []
                newHouse.HouseAdmins.push({ email: house.adminEmail, name: house.adminName })
            }
            let createdHouseRes = res
            if (houseAdminRes) {
                createdHouseRes.house.password = houseAdminRes.password
            }
            console.log("createHouseRes", createdHouseRes);
            this.setState({ createdHouse: createdHouseRes })
            this.setState({ uploading: false })
            //this.props.fetchHouses()

            console.log("newHouse", newHouse)
            this.props.addHouseToState(newHouse)
        } catch (e) {
            console.error("error creating house", e)
            this.props.deleteHouseFromState(undefined)
            alert("error creating house")
            this.setState({ uploading: false, uploadError: true })
        }
    }

    submitOrganization = async (org) => {
        console.log("saving org", org)
        try {
            this.setState({ uploading: true })
            let res = await addOrganizationToHouse(org.org, this.state.adminHouseId)
            console.log("res", res);
            this.setState({ uploading: false, createdOrg: res })
            this.props.getOrganizations()
            this.props.fetchHouses()
        } catch (e) {
            console.error("error creating org", e)
            this.setState({ uploading: false, uploadError: true })
        }
    }

    submitAdmin = async ({ name, email }) => {
        try {
            this.setState({ uploading: true })
            const { adminHouseId } = this.state;
            let res = await createHouseAdmin({ name, email, houseId: adminHouseId })

            if (res && res.errors) {
                this.setState({ uploading: false, uploadError: true })
                return
            }

            console.log("submitAdmin res", res)

            this.setState({ uploading: false, createdAdmin: res })
            this.props.fetchHouses()
        } catch (e) {
            console.error("error creating house admin", e)
            this.setState({ uploading: false, uploadError: true })
        }
    }

    isDuplicateName = (name) => {
        const { houses } = this.props;
        if (_.find(houses, (h) => {
            return h.name.toLowerCase() === name.toLowerCase()
        })) {
            return true
        }
    }

    onRemoveHouse = async (houseId) => {
        const { houses } = this.props;
        const houseIndex = _.findIndex(houses, { id: houseId })
        const house = houses[houseIndex]
        try {
            await deleteHouse({ id: houseId })
            this.props.deleteHouseFromState(houseId)
            //this.props.fetchHouses()
        } catch (e) {
            console.error("onRemoveHouse error", e)
            alert("Error Removing house")
            this.props.addHouseToState(house)
        }
    }

    render() {
        const { uploading, uploadError, createdHouse, addAdminMode, createdAdmin, editRow, createdOrg } = this.state;
        const { houses, isLoading, isFetchFailed, organizations } = this.props;

        console.log("organizations", organizations)

        return (
            <div style={{ flex: 1, height: "100%", width: "100%" }}>

                <Content
                    houses={houses}
                    isFetchFailed={isFetchFailed}
                    isLoading={isLoading}
                    addNewItemMode={this.state.addNewItemMode}
                    setAddNewItemMode={(addNewItemMode) => {
                        this.setState({ addNewItemMode })
                    }}
                    createAdmin={(adminHouseId) => {
                        this.setState({ addAdminMode: true, adminHouseId })
                    }}
                    createOrganization={(adminHouseId) => {
                        this.setState({ createOrganizationOpen: true, adminHouseId, createdOrg: null })
                    }}
                    editRow={editRow}
                    setEditRow={(row) => {
                        console.log("setEditRow", row)
                        this.setState({ editRow: row })
                    }}
                    removeLocalHouseAdmin={(houseId, adminId) => {
                        let houseIndex = _.findIndex(houses, { id: houseId })
                        if (houseIndex === -1) {
                            return
                        }

                        _.remove(houses[houseIndex].HouseAdmins, (a) => {
                            return a.id === adminId
                        })

                        this.setState({ houses })

                    }}
                    removeLocalHouseOrganizarion={(houseId) => {
                        let houseIndex = _.findIndex(houses, { id: houseId })
                        if (houseIndex === -1) {
                            return
                        }

                        houses[houseIndex].Organization = null
                        houses[houseIndex].OrganizationId = null


                        this.setState({ houses })

                    }}
                    editNameValidation={this.isDuplicateName}
                    onRemoveItem={this.onRemoveHouse}

                />
                <CreateHouseDialog
                    open={this.state.addNewItemMode}
                    handleClose={() => {
                        this.setState({
                            addNewItemMode: false,
                            createdHouse: null
                        });
                    }}
                    formProps={this.props}
                    onSubmit={this.submit}
                    uploading={uploading}
                    uploadError={uploadError}
                    createdHouse={createdHouse}
                    isDuplicateName={this.isDuplicateName}
                    organizations={organizations}
                />

                <AttachOrganizationDialog
                    open={this.state.createOrganizationOpen}
                    handleClose={() => {
                        this.setState({
                            createOrganizationOpen: false,
                            createdOrg: null
                        });
                    }}
                    formProps={this.props}
                    onSubmit={this.submitOrganization}
                    uploading={uploading}
                    uploadError={uploadError}
                    organizations={organizations}
                    createdOrg={createdOrg}

                />

                <CreateHouseAdminDialog
                    handleClose={this.closeCreateAdminDialog}
                    onSubmit={this.submitAdmin}
                    createdAdmin={createdAdmin}
                    uploading={uploading}
                    uploadError={uploadError}
                    open={this.state.addAdminMode}
                />
            </div>
        );
    }
}

Houses.propTypes = {};

const mapStateToProps = ({ houses, organizations }) => ({
    houses: houses.houses,
    filtered: houses.filtered,
    isLoading: houses.isLoading || houses.isLoadingSearch,
    isFetchFailed: houses.isFetchFailed,
    organizations: organizations.organizations,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchHouses,
            getOrganizations,
            addHouseToState,
            deleteHouseFromState
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Houses);


