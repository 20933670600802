import { apiRoutes, getAuthHeader } from "./index";
import { firebaseConfig } from "./firebase";
import {
    FETCH_HOUSES_REQUESTED,
    FETCH_HOUSES_FAILED,
    FETCH_HOUSES_SUCCESS
} from "../modules/houses";

import { DYNAMIC_LINK_DOMAIN, dynamicLinkToHouse } from "./constants"

export const createHouse = (house) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createHouse, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify(house),
        headers: headers
    })
        .then(response => {
            return response.json()
        })


}

export const updateHouse = ({ id, name, type, maxResidents, country, configVersion, active, useLegacyVersion }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.house(id), {
        method: 'PUT',
        // mode: 'no-cors',
        body: JSON.stringify({ name, type, maxResidents, country, configVersion, active, useLegacyVersion }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const deleteHouse = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.house(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const deleteHouseOrganization = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.houseOrganization(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })


}

export const deleteHouseAdmin = ({ id }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.houseAdmin(id), {
        method: 'DELETE',
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const createHouseAdmin = ({ name, email, houseId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.createHouseAdmin, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({ name, email, houseId }),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const getHouses = (house) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")

    return fetch(apiRoutes.getHouses, {
        method: 'GET',
        // mode: 'no-cors',
        body: JSON.stringify(house),
        headers: headers
    })
        .then(response => {
            return response.json()
        })
}

export const fetchHouses = () => {
    return dispatch => {
        dispatch({
            type: FETCH_HOUSES_REQUESTED
        });

        getHouses()
            .then(results => {

                console.log("results", results);

                let houses = results && results.data ? results.data : [];
                /* results.forEach(document => {
                     songs.push(document.data());
                 });*/
                console.log("HOUSES", houses);
                dispatch({
                    type: FETCH_HOUSES_SUCCESS,
                    payload: {
                        houses: houses
                    }
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: FETCH_HOUSES_FAILED
                });
            });
    };
};

export const getHouseLink = (houseKey) => {
    return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`, {
        method: 'POST',
        // mode: 'no-cors',
        body: JSON.stringify({
            "longDynamicLink": `${DYNAMIC_LINK_DOMAIN}/?link=${dynamicLinkToHouse(houseKey)}&apn=fun.together.solo&ibi=fun.together.solo&ofl=http://imsolo.ai/deeplink&ifl=https://apps.apple.com/app/id1537581892&afl=https://play.google.com/store/apps/details?id=fun.together.solo`
        }),
    })
        .then(response => {
            return response.json()
        })
}
