import React, { useCallback, Fragment } from "react";
import { Button, Dialog, Slide, CircularProgress } from "@material-ui/core";

import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';
import Delete from "@material-ui/icons/Delete";

import {
    Container,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    List,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    Switch,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField,
    Divider,
    OutlinedInput,
    Select,
    Grid,
    Card,
    Chip,
    CardContent,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormGroup,
    Toolbar,
    IconButton,
    DialogContentText,
    DialogContent,
    DialogTitle,
    DialogActions,
    LinearProgress,
    InputBase
} from "@material-ui/core";
import { LANGUAGES_MAP } from "../../api/constants";
import { fade, makeStyles } from "@material-ui/core/styles";


function AdminForm({onSubmit, uploading, uploadError, result, handleClose, admins}) {


    let textArea = React.createRef()


    return (
        <RootRef style={{padding: 50}}>
            <Paper style={{padding: 50}}>
                {uploading ? (<CircularProgress/>) : (
                    <div>
                        {admins.map(a => {
                            return <div key={a.id} style={{cursor: "pointer"}} onClick={() => {
                                console.log("a", a.id)
                                if (window.confirm(`Do you really want to delete ${a.email}?`)) {
                                    onSubmit(a.id)
                                }
                            }}>
                                {a.email}
                                <Delete/>
                            </div>
                        })}

                    </div>

                )}

                {uploadError && (<div style={{color: "red"}}>error delete admin</div>)}


            </Paper>


            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={uploading}
                onClick={() => {
                    handleClose()
                }}
            >
                Close
            </Button>


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateAdminsEditDialog({
                                                   open,
                                                   handleClose,
                                                   formProps,
                                                   onSubmit,
                                                   uploading,
                                                   uploadError,
                                                   result,
                                                   admins
                                               }) {

    console.log("CreateAdminsEditDialog", {
        open,
        handleClose,
        formProps,
        onSubmit,
        uploading,
        uploadError,
        result,
        admins
    })

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <AdminForm
                onSubmit={onSubmit}
                uploading={uploading}
                uploadError={uploadError}
                handleClose={handleClose}
                result={result}
                admins={admins}
            />
        </Dialog>
    );
}

