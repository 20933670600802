import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { uploadTranslations } from "../../api/tarnslations";

import { Button, CircularProgress, List } from "@material-ui/core";
import CreateTranslationsDialog from "./CreateTranslationsDialog";
import { useStyles } from "../common/styles";



function Content({ addNewItemMode, setAddNewItemMode }) {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                component="span"
                color={addNewItemMode ? "secondary" : "primary"}
                onClick={() => {
                    setAddNewItemMode(!addNewItemMode)
                }}
            >
                {addNewItemMode ? "Cancel" : "Upload CSV"}
            </Button>
        </div>
    );
}



class Translations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewItemMode: false,
            uploading: false,
            uploadError: false
        };
    }

    render() {

        const {

        } = this.props;

        const { uploading, uploadError } = this.state;

        return (
            <div style={{ flex: 1, height: "100%", width: "100%" }}>

                <Content {...this.props} addNewItemMode={this.state.addNewItemMode} setAddNewItemMode={(addNewItemMode) => { this.setState({ addNewItemMode }) }} />
                <CreateTranslationsDialog
                    open={this.state.addNewItemMode}
                    handleClose={() => {
                        this.setState({
                            addNewItemMode: false
                        });
                    }}
                    formProps={this.props}
                    onSubmit={(file) => {
                        this.setState({ uploading: true })
                        console.log("FILLEE", file)
                        uploadTranslations(file).then(data => {
                            console.log(data)
                            this.setState({
                                addNewItemMode: false,
                                uploadError: false,
                                uploading: false
                            });
                        })
                            .catch(error => {
                                console.log(error)
                                this.setState({
                                    uploadError: true,
                                    uploading: false
                                });
                            })
                    }}
                    uploading={uploading}
                    uploadError={uploadError}
                />
            </div>
        );
    }
}

Translations.propTypes = {};

Translations.defaultProps = {};

const mapStateToProps = ({ youtubeSongs }) => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
