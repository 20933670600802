import React from "react";
import {Button, Dialog, Slide, CircularProgress, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper';

import {
    TextField,
} from "@material-ui/core";

import { fade, makeStyles } from "@material-ui/core/styles";
import {UNIT_TYPES} from "../SdkOrganizations";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent"
    },
    list: {
        width: "100%",
        backgroundColor: "transparent"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    chip: {
        margin: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.secondary.main, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));

function TierForm({ onSubmit, uploading, minUnits, setMinUnits,setUnitType,unitType, discount, setDiscount, setCurrency, currency }) {

    const classes = useStyles();

    const [error, setError] = React.useState("");

    const validate = () => {
        let isValid = true;
        if (!minUnits) {
            isValid = false;
            setError("min Units is required")
        }

        return isValid;
    }



    return (
        <RootRef style={{ padding: 50 }}>
            <Paper style={{ padding: 50 }}>
                {uploading ? (<CircularProgress />) : (
                    <form
                        style={{ display: "flex", flexWrap: "wrap" }}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField
                            id="outlined-dense"
                            label="Min. Units"
                            margin="dense"
                            variant="outlined"
                            type={"number"}
                            value={minUnits}
                            onChange={event => {
                                setMinUnits(event.target.value);
                            }}
                        />

                        <TextField
                            id="outlined-dense"
                            label="discount"
                            margin="dense"
                            variant="outlined"
                            type={"number"}
                            value={discount}
                            onChange={event => {
                                setDiscount(event.target.value);
                            }}
                        />
                        <FormControl variant="outlined">
                            <InputLabel id="currency-label">Currency</InputLabel>
                            <Select
                                labelId="currency-label"
                                id="currency"
                                value={currency}
                                onChange={(event) => setCurrency(event.target.value)}
                                label="Currency"
                            >
                                <MenuItem value="usd">USD</MenuItem>
                                <MenuItem value="jpy">JPY</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined">
                            <InputLabel id="currency-label">Unit Type</InputLabel>
                            <Select
                                labelId="currency-label"
                                id="currency"
                                value={unitType}
                                onChange={(event) => setUnitType(event.target.value)}
                                label="Currency"
                            >
                                {Object.keys(UNIT_TYPES).map((unitType) => {
                                    return <MenuItem value={unitType}>{unitType}</MenuItem>
                                })}

                            </Select>
                        </FormControl>
                    </form>

                )}

                {error}

            </Paper>

            <Button
                variant="contained"
                component="span"
                color={"primary"}
                disabled={uploading}
                onClick={() => {
                    if (!validate()) {
                        return;
                    }
                    setError("")
                    onSubmit()
                }}
            >
                Submit
            </Button>


        </RootRef>
    )

}


const CreateNewItemDialogTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrgPricingDialog({ open, handleClose, formProps, onSubmit, uploading, unitType, minUnits, setMinUnits, discount, setDiscount,setUnitType, currency, setCurrency }) {

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={CreateNewItemDialogTransition}>
            <TierForm
                onSubmit={onSubmit}
                uploading={uploading}
                handleClose={handleClose}
                minUnits={minUnits}
                setMinUnits={setMinUnits}
                discount={discount}
                setDiscount={setDiscount}
                currency={currency}
                setCurrency={setCurrency}
                setUnitType={setUnitType}
                unitType={unitType}
            />
        </Dialog>
    );
}

