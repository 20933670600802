import firebase from "firebase/compat/app";
import { FIRESTORE_COLLECTION_NAMES } from "./constants";
import { decodeQueryDataFromUrl } from "../utils";

const firestore = firebase.firestore();

export async function fetchAllYoutubePlaylists() {
  return firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .get();
}

export async function setPlaylistEnergy(playlistRef, energy, item) {
  const name = `${energy} ${item.period} ${
    item.languages ? item.languages[0] : item.style ? item.style[0] : ""
  }`;
  const data = {
    energy: energy,
    name: name
  };
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update(data);
  return data;
}

export async function setPlaylistPeriod(playlistRef, period, item) {
  const name = `${item.energy} ${period} ${
    item.languages ? item.languages[0] : item.style ? item.style[0] : ""
  }`;
  const data = {
    period: period,
    name: name
  };
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update(data);
  return data;
}

export async function setPlaylistLanguage(playlistRef, language, item) {
  const name = `${item.energy} ${item.period} ${language}`;
  const data = {
    languages: [language],
    style: null,
    name: name
  };
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update(data);
  return data;
}

export async function setPlaylistStyle(playlistRef, style, item) {
  const name = `${item.energy} ${item.period} ${style}`;
  const data = {
    style: [style],
    languages: null,
    name: name
  };
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update(data);
  return data;
}

export async function setPlaylistName(playlistRef, name) {
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update({
      name: name
    });
  return name;
}

export async function setPlaylistUrl(playlistRef, url) {
  const playlistId = decodeQueryDataFromUrl(url).list;
  if (!playlistId) {
    throw new Error("empty playlistId");
  }
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .update({
      id: playlistId,
      url: url
    });
  return url;
}

export async function createPlaylist(url, style, language, period, energy) {
  const playlistId = decodeQueryDataFromUrl(url).list;
  if (!playlistId) {
    throw new Error("empty playlistId");
  }
  const name = `${energy} ${period} ${style || language}`;
  const newDocRef = firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc();
  const data = {
    ref: newDocRef.id,
    url: url,
    id: playlistId,
    style: style ? [style] : null,
    languages: language ? [language] : null,
    period: period,
    energy: energy,
    name: name,
    createdAt: Date.now().toString()
  };
  await newDocRef.set(data);
}

export async function removePlaylist(playlistRef) {
  await firestore()
    .collection(FIRESTORE_COLLECTION_NAMES.youtubePlaylists)
    .doc(playlistRef)
    .delete();
}
